var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "10",
      "height": "12",
      "viewBox": "0 0 10 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.89784 10.6397C9.87734 8.62148 8.58959 6.80072 6.69346 6.10873C6.62571 6.0841 6.55821 6.05785 6.48471 6.02922L6.24871 5.93822L6.35759 5.8721C7.92134 4.92235 8.10971 3.41622 7.79584 2.41485C7.43059 1.24972 6.34246 0.409725 5.14971 0.372475C5.11671 0.37135 5.08409 0.37085 5.05134 0.37085C3.79534 0.37085 2.71284 1.12322 2.29346 2.2876C1.81096 3.62635 2.23534 4.73372 3.66921 5.87835L3.74346 5.9376L3.65984 5.98222C3.64734 5.98898 3.63571 5.99572 3.62421 6.00235C3.59646 6.01847 3.57034 6.03335 3.54271 6.04347C1.30446 6.87322 0.128339 8.57948 0.141714 10.965C0.144714 11.5082 0.258214 11.6248 0.815339 11.6248H8.92909C9.39621 11.6248 9.66634 11.6121 9.78321 11.494C9.89946 11.3767 9.90259 11.1022 9.89784 10.6397ZM2.98346 3.29985C2.97921 2.75172 3.18884 2.24047 3.57371 1.8501C3.95559 1.46297 4.46334 1.24997 5.00384 1.24997H5.01684C6.13884 1.24997 7.05421 2.15422 7.05759 3.27247C7.05921 3.81535 6.84734 4.32522 6.46109 4.7131C6.07471 5.10122 5.56296 5.31397 5.02021 5.31472C3.90446 5.31472 2.99209 4.40997 2.98346 3.29985ZM9.01721 10.679L9.01434 10.75H1.02359L1.02109 10.6785C0.981589 9.6831 1.36421 8.68472 2.09884 7.92035C2.87209 7.11572 3.94096 6.62497 5.03171 6.62497H5.05396C6.13246 6.62497 7.19159 7.1281 7.95946 7.9376C8.68784 8.70535 9.06347 9.69772 9.01721 10.679Z",
      "fill": "#909090"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }