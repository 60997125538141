<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M109.659,30.487c-0.332-0.577-0.801-0.934-1.59-1.166c-0.524-0.15-1.187-0.228-1.993-0.23
		c-2.101-0.007-4.2-0.028-6.296-0.028c-1.731,0-3.46,0.014-5.186,0.06l-0.181,0.002c-0.702,0.001-1.068-0.123-1.564-0.48
		S92,27.752,92,27.063v-0.246C89,21.571,84.457,18,78.865,18h-0.023C73.352,19,68,21.592,66,26.732v0.124
		c0,0.77-0.521,1.34-1.066,1.694C64.387,28.902,63.891,29,63.143,29h-0.036c-7.114,0-14.229,0.024-21.345,0.024
		c-6.117,0-12.235,0.035-18.354,0.038c-1.311-0.001-2.242,0.18-2.852,0.509c-0.606,0.332-1.031,0.798-1.262,1.616
		C19.146,31.73,19,32.433,19,33.292c0,12.006,0,24.013,0,36.02c0,12.006,0,24.012,0,36.018c0,1.288,0.24,2.199,0.572,2.795
		c0.332,0.591,0.837,1.186,1.674,1.413c0.556,0.146,1.305,0.463,2.182,0.463c4.571,0,9.142,0,13.713,0c9.142,0,18.282,0,27.424,0
		c7.546,0,15.091,0,22.636,0c6.288,0,12.575-0.246,18.863-0.248c1.219,0.002,2.102-0.292,2.695-0.617
		c0.591-0.327,0.947-0.844,1.179-1.612c0.15-0.512,0.226-1.191,0.226-1.983c0.001-12.067,0.002-24.147,0.002-36.214
		s-0.001-24.139-0.002-36.206C110.164,31.933,109.99,31.067,109.659,30.487z M71,32.174v-0.002V32.174
		C71,28.084,74.753,25,78.849,25l0,0h0.03C82.966,25,86,28.023,86,32.11v-0.008v0.008v0.001v0.06c0,2.075-0.654,4.111-2.008,5.471
		C82.639,39.003,80.947,40,78.881,40h-0.059C74.742,40,71,36.266,71,32.19V32.174z M104,35.809c0,22.328,0,44.562,0,66.899V103
		h-0.681c-25.728,0-51.458,0-77.333,0H25v-0.292c0-22.223,0-44.461,0-66.913V35h0.986c1.641,0,3.241,0,4.841,0
		c10.842,0,21.686,0,32.526,0c0.171,0,0.352,0.142,0.539,0.142c0.39,0.002,0.828,0.092,1.255,0.328
		c0.431,0.236,0.75,0.734,0.901,1.364l-0.008-0.014l0.009,0.042c0.081,0.328,0.244,0.69,0.401,1.075
		c0.155,0.385,0.313,0.806,0.315,1.288c0.001,0.321-0.081,0.668-0.271,0.989c-0.188,0.322-0.475,0.62-0.865,0.902v0
		c-1.13,0.814-2.095,1.969-3.113,3.087v0c-0.658,0.724-0.961,1.522-0.962,2.286c0.002,0.779,0.313,1.535,0.961,2.168
		c0.596,0.578,1.335,0.868,2.091,0.869c0.766-0.001,1.553-0.297,2.23-0.945c1.487-1.421,2.851-3.002,4.281-4.529l0.258-0.274
		l0.335,0.171c2.439,1.244,4.771,1.96,7.16,1.96c0.763,0,1.532-0.073,2.317-0.226c2.544-0.499,4.681-1.466,6.423-2.898
		c1.741-1.433,3.095-3.335,4.05-5.739l-0.007,0.017l0.007-0.016c0.222-0.561,0.486-1.015,0.903-1.33
		c0.418-0.315,0.938-0.432,1.524-0.43l0.115,0.001c1.274,0.028,2.55-0.118,3.84-0.118c1.725,0,3.475-0.169,5.274-0.169H104V35.809z"
      />

      <line
        fill="none"
        stroke="#FFFFFF"
        stroke-width="0"
        stroke-linecap="round"
        stroke-linejoin="round"
        x1="86"
        y1="32"
        x2="86"
        y2="32"
      />

      <line
        fill="none"
        stroke="#FFFFFF"
        stroke-width="0"
        stroke-linecap="round"
        stroke-linejoin="round"
        x1="71"
        y1="32"
        x2="71"
        y2="32"
      />
      <path
        d="M89,60.422v-0.057c0-0.957-0.447-1.681-1.076-2.208c-0.633-0.523-1.615-0.847-2.834-0.848
		c-7.353-0.002-14.725-0.004-22.076-0.004c-6.242,0-12.493,0.001-18.736,0.004c-1.21,0.001-2.394,0.328-3.03,0.854
		C40.612,58.695,40,59.42,40,60.365v0.002v0.057c0,0.95,0.59,1.676,1.238,2.212c0.65,0.531,1.745,0.863,3.001,0.864
		c2.98,0.004,6.02,0.005,9,0.005c3.727,0,7.48-0.002,11.207-0.002c3.794,0,7.602,0.002,11.394,0.002
		c3.034,0,6.076-0.001,9.109-0.005c1.259-0.001,2.318-0.332,2.968-0.863C88.563,62.102,89,61.376,89,60.422z"
      />
      <path
        d="M88.793,78.183c0-0.188,0.001-0.377-0.035-0.563c-0.163-0.841-0.527-1.467-1.082-1.906
		c-0.557-0.437-1.316-0.695-2.306-0.703c-1.761-0.015-3.52-0.019-5.282-0.019c-2.547,0-5.094,0.009-7.644,0.009
		c-5.979,0.001-11.96,0.006-17.939,0.006c-3.362,0-6.723-0.001-10.085-0.006h-0.002h-0.012c-0.788-0.001-1.498,0.084-2.082,0.326
		c-0.584,0.245-1.058,0.628-1.443,1.317c-0.291,0.525-0.42,1.051-0.42,1.549c0.006,1.579,1.297,2.971,3.388,2.997
		c2.923,0.019,5.846-0.078,8.77-0.078c3.913,0,7.827-0.111,11.741-0.111c0.108,0,0.216,0,0.324,0c3.172,0,6.345,0.105,9.517,0.105
		c3.534,0,7.03,0.048,10.563,0.034C85.564,81.139,86,81.102,87,80.848v0.013C88,80.439,88.793,79.342,88.793,78.183z"
      />

      <line
        fill="none"
        stroke="#FFFFFF"
        stroke-width="0"
        stroke-linecap="round"
        stroke-linejoin="round"
        x1="44"
        y1="74"
        x2="44"
        y2="74"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NoteIcon'
}
</script>

<style scoped></style>
