var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "37",
      "height": "37",
      "viewBox": "0 0 37 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5 5L32 32",
      "stroke": "#CAA5A5",
      "stroke-width": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M5 32L32 5",
      "stroke": "#CAA5A5",
      "stroke-width": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }