import axios from 'axios'
import {
  PARTNER_GET_ALL,
  PARTNER_GET_ALL_SUCCESS,
  PARTNER_GET_ALL_ERROR,
  PARTNER_GET,
  PARTNER_GET_SUCCESS,
  PARTNER_GET_ERROR,
  PARTNER_CREATE,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_ERROR,
  PARTNER_EDIT,
  PARTNER_EDIT_SUCCESS,
  PARTNER_EDIT_ERROR,
  PARTNER_UPDATE,
  PARTNER_DELETE,
  PARTNER_GET_ALL_WITH_TASKS
} from '@/store/actions/partner'
import { USER_GET_ALL_WITH_TASKS } from "@/store/actions/user";

function initialState() {
  return {
    status: '',
    partners: [],
    partnerPagination: {},
    partner: {}
  }
}

const state = initialState()

const getters = {
  allPartners: state => state.partners,
  partnerPagination: state => state.partnerPagination,
  detailPartner: state => state.partner
}

const actions = {
  [PARTNER_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/partners', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('PARTNER_GET_ALL_SUCCESS', {
            partners: data.data.partners,
            partnerPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [PARTNER_GET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/partners/' + params)
        .then(response => {
          let data = response.data
          commit('PARTNER_GET_SUCCESS', {
            partner: data.data.partner
          })
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [PARTNER_GET_ALL_WITH_TASKS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/partners/tasks', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('PARTNER_GET_ALL_SUCCESS', {
            partners: data.data.partners
          })
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [PARTNER_CREATE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/partners`, params)
        .then(response => {
          let data = response.data
          commit('PARTNER_CREATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_CREATE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [PARTNER_EDIT]: ({ commit }, { id, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/partners/${id}`, params)
        .then(response => {
          let data = response.data
          commit('PARTNER_EDIT_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_EDIT_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [PARTNER_UPDATE]: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/partners/' + id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          let data = response.data
          commit('PARTNER_GET_SUCCESS', {
            partner: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [PARTNER_DELETE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/partners/' + data.id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('PARTNER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [PARTNER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.partners = params.partners
    if (params.partnerPagination) {
      state.partnerPagination = params.partnerPagination
    }

  },
  [PARTNER_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [PARTNER_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.partner = params.partner
  },
  [PARTNER_GET_ERROR]: state => {
    state.status = 'error'
  },
  [PARTNER_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [PARTNER_CREATE_ERROR]: state => {
    state.status = 'error'
  },
  [PARTNER_EDIT_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [PARTNER_EDIT_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
