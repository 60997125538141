<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M113.086,101.072c0.022-2.729,0.045-5.55-0.043-8.318c-0.32-10.023-4.201-18.445-11.536-25.031
	c-1.403-1.261-2.922-2.344-4.528-3.491c-0.728-0.519-1.458-1.04-2.18-1.581l-0.327-0.244l0.482-0.546
	c0.141-0.163,0.28-0.325,0.43-0.479c8.724-9.055,9.55-22.572,1.964-32.141c-4.653-5.868-11.519-9.233-18.836-9.233
	c-4.498,0-8.998,1.281-13.016,3.705c-0.469,0.282-0.899,0.42-1.316,0.42c-0.601,0-1.096-0.274-1.637-0.605
	c-3.777-2.314-8.146-3.538-12.633-3.538c-5.51,0-10.861,1.864-15.07,5.248c-8.064,6.485-11.139,17.005-7.832,26.801
	c1.248,3.696,3.397,7.011,6.572,10.132l0.274,0.27l-0.492,0.481c-0.08,0.08-0.124,0.125-0.191,0.167l-0.573,0.343
	c-0.25,0.147-0.499,0.295-0.745,0.449c-8.896,5.558-14.226,13.199-15.839,22.712c-0.68,4.008-0.717,8.208-0.753,12.271
	c-0.014,1.521-0.027,3.042-0.072,4.557c-0.042,1.385,0.226,2.354,0.817,2.965c0.596,0.613,1.567,0.911,2.971,0.911
	c30.119,0.012,60.237,0.015,90.356,0c2.639-0.002,3.721-1.093,3.736-3.766L113.086,101.072z M69.281,29.348l-0.211-0.312l0.313-0.21
	c2.473-1.661,5.481-2.539,8.701-2.539c5.001,0,9.814,2.063,13.205,5.661c5.996,6.358,6.527,16.064,1.264,23.078
	c-3.453,4.606-8.433,7.143-14.02,7.143c0,0,0,0-0.001,0c-3.091,0-6.167-0.778-9.145-2.314l-0.375-0.193l0.236-0.35
	C75.986,49.352,75.998,39.271,69.281,29.348z M32.072,44.135c-0.006-4.731,1.856-9.344,5.227-12.717
	C40.693,28.02,45.21,26,49.998,26h0.041c4.781,0,9.289,2.059,12.691,5.494s5.25,8.041,5.233,12.837
	C67.929,54.159,60,62.191,50,62.191v0.019C40,62.189,32.082,54.261,32.072,44.135z M78.599,98.277
	c-0.007,0.538-0.015,1.075-0.019,1.613c-0.002,0.18-0.055,0.333-0.116,0.51c-0.027,0.08-0.059,0.195-0.09,0.303L78.295,101H21.761
	l-0.041-0.354c-1.969-15.605,4.382-27.775,17.423-33.369c0.776-0.332,1.942-0.363,2.797-0.076
	c5.361,1.803,10.768,1.799,16.193,0.002c0.896-0.292,2.229-0.188,3.146,0.245c11.077,5.214,16.901,14.116,17.312,26.46
	C78.64,95.366,78.619,96.846,78.599,98.277z M106.908,97.857c-0.018,0.874,0.092,1.758,0.092,2.653V101H84.786l0.03-0.461
	c1.001-13.438-2.218-23.478-10.129-31.534l-0.732-0.759l1.039,0.102c4.215,0.443,8.049-0.009,12.05-1.426
	c0.662-0.238,1.657,0.128,2.444,0.49c10.577,4.86,16.438,13.234,17.293,24.891C106.916,94.137,106.945,95.943,106.908,97.857z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersIcon'
}
</script>

<style scoped></style>
