import axios from 'axios'
import {
  SERVICE_GET_ALL,
  SERVICE_GET_ALL_SUCCESS,
  SERVICE_GET_ALL_ERROR,
  SERVICE_CHANGE_BG_COLOR,
  SERVICE_GET_ALL_BY_SITE_ID,
  SERVICE_GET_ALL_BY_SITE_ID_SUCCESS,
  SERVICE_GET_ALL_BY_SITE_ID_ERROR
} from '@/store/actions/service'

function initialState() {
  return {
    status: '',
    services: [],
    servicesBySites: [],
    servicePagination: {}
  }
}

const state = initialState()

const getters = {
  allServices: state => state.services,
  servicesBySite: state => state.servicesBySites,
  servicePagination: state => state.servicePagination
}

const actions = {
  [SERVICE_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/service-types', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('SERVICE_GET_ALL_SUCCESS', {
            services: data.data.serviceType,
            servicePagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('SERVICE_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [SERVICE_GET_ALL_BY_SITE_ID]: ({ commit, dispatch }, site_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/service-types/' + site_id + '/by-site-id')
        .then(response => {
          commit('SERVICE_GET_ALL_BY_SITE_ID_SUCCESS', {
            servicesBySites: response.data.data.service_type
          })

          resolve(response)
        })
        .catch(error => {
          commit('SERVICE_GET_ALL_BY_SITE_ID_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [SERVICE_CHANGE_BG_COLOR]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/service-types/1/color', params)
        .then(response => {
          let data = response.data

          commit('SERVICE_CHANGE_BG_COLOR', {
            status: data.data.status
          })

          resolve(response)
        })
        .catch(error => {
          commit('SERVICE_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  }
}

const mutations = {
  [SERVICE_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.services = params.services
    state.servicePagination = params.servicePagination
  },
  [SERVICE_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [SERVICE_GET_ALL_BY_SITE_ID_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.servicesBySites = params.servicesBySites
  },
  [SERVICE_GET_ALL_BY_SITE_ID_ERROR]: state => {
    state.status = 'error'
  },
  [SERVICE_CHANGE_BG_COLOR]: state => {
    state.status = 'success'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
