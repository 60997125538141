<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.313,100.086c-9.132-0.193-16.765-3.537-22.938-10.088
		c-1.179-1.252-2.377-2.059-4.104-2.478c-13.251-3.21-22.313-14.959-22.047-28.39c0.264-13.259,9.817-24.554,23.1-27.41
		c0.849-0.183,1.729-0.802,2.328-1.46c11.628-12.76,29.648-14.595,43.07-3.783c3.877,3.122,7.803,5.42,12.393,7.249
		c11.373,4.534,18.423,16.665,17.209,28.515c-1.281,12.509-10.298,22.723-22.362,25.493c-1.192,0.275-2.456,1.03-3.3,1.925
		C80.277,96.428,72.498,99.936,63.313,100.086z M63.325,94.315c8.07-0.236,14.753-3.376,20.021-9.621
		c0.866-1.027,2.254-2.013,3.535-2.231c11.466-1.961,19.772-11.402,19.766-22.702c-0.006-11.3-8.3-20.72-19.799-22.659
		c-1.137-0.19-2.27-1.021-3.177-1.814c-1.53-1.339-2.737-3.065-4.326-4.313c-11.415-8.961-26.865-7.222-36.068,4.009
		c-0.969,1.184-2.007,1.974-3.612,2.222c-11.281,1.738-19.573,11.181-19.686,22.299c-0.114,11.17,7.901,20.778,19.152,22.748
		c1.927,0.337,3.215,1.174,4.415,2.633C48.643,91.073,55.311,94.07,63.325,94.315z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.467,102.975c-0.025,4.725-3.993,8.629-8.717,8.578
		c-4.726-0.051-8.604-4.038-8.527-8.763c0.074-4.649,3.968-8.482,8.617-8.484C27.561,94.303,31.492,98.253,31.467,102.975z
		 M22.915,105.789c1.509-0.041,2.775-1.329,2.789-2.836c0.013-1.582-1.353-2.924-2.933-2.881c-1.512,0.04-2.777,1.327-2.79,2.835
		C19.969,104.489,21.334,105.831,22.915,105.789z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.576,42.519c-1.188,0-2.378,0.021-3.564-0.006
		c-2.064-0.046-3.313-1.169-3.29-2.915c0.023-1.671,1.24-2.808,3.18-2.828c3.572-0.037,7.145,0,10.716-0.016
		c1.407-0.008,2.776,0.456,2.985,1.832c0.165,1.083-0.219,2.568-0.923,3.399c-1.763,2.083-3.845,3.896-6.271,6.279
		c1.654,0,2.737-0.008,3.82,0.001c2.376,0.021,3.698,1.076,3.673,2.921c-0.026,1.828-1.338,2.83-3.755,2.837
		c-3.253,0.01-6.508-0.023-9.759,0.016c-1.519,0.018-2.844-0.3-3.448-1.833c-0.601-1.519,0.106-2.696,1.205-3.736
		c1.898-1.796,3.764-3.626,5.644-5.442C61.718,42.857,61.646,42.688,61.576,42.519z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.286,65.53c-1.26,0-2.52,0.039-3.777-0.01
		c-1.899-0.073-3.096-1.265-3.045-2.958c0.049-1.608,1.215-2.747,3.036-2.771c3.698-0.047,7.404-0.126,11.094,0.063
		c0.959,0.05,2.366,0.852,2.669,1.648c0.342,0.9,0.03,2.497-0.623,3.246c-1.876,2.149-4.067,4.021-6.137,6.003
		c0.069,0.176,0.138,0.353,0.207,0.53c1.229,0,2.461-0.026,3.69,0.005c2.049,0.052,3.288,1.207,3.235,2.965
		c-0.051,1.67-1.262,2.76-3.227,2.779c-3.57,0.033-7.151,0.132-10.713-0.068c-1.023-0.058-2.54-0.837-2.847-1.655
		c-0.351-0.932,0.05-2.574,0.739-3.386c1.805-2.123,3.946-3.962,5.952-5.913C44.457,65.849,44.371,65.69,44.286,65.53z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.76,65.527c-1.273,0-2.552,0.047-3.822-0.011
		c-1.79-0.081-2.954-1.244-2.958-2.858c-0.003-1.611,1.161-2.834,2.946-2.861c3.698-0.059,7.404-0.144,11.094,0.06
		c1.003,0.055,2.438,0.896,2.789,1.743c0.357,0.855-0.044,2.463-0.706,3.222c-1.861,2.137-4.027,4.005-6.072,5.979
		c0.086,0.163,0.169,0.324,0.253,0.486c1.309,0,2.62-0.047,3.927,0.011c1.788,0.081,2.953,1.246,2.955,2.859
		c0.003,1.612-1.161,2.834-2.944,2.863c-3.698,0.057-7.407,0.142-11.096-0.062c-1.003-0.056-2.438-0.895-2.791-1.742
		c-0.357-0.855,0.045-2.462,0.71-3.224c1.859-2.136,4.025-4.004,6.071-5.978C78.996,65.853,78.878,65.69,78.76,65.527z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OffDayIcon'
}
</script>

<style scoped></style>
