import axios from 'axios'
import {
  PREFECTURE_GET_ALL,
  PREFECTURE_GET_ALL_SUCCESS,
  PREFECTURE_GET_ALL_ERROR
} from '@/store/actions/prefecture'

function initialState() {
  return {
    status: '',
    prefectures: [],
    prefecturePagination: {}
  }
}

const state = initialState()

const getters = {
  allPrefectures: state => state.prefectures,
  prefecturePagination: state => state.prefecturePagination
}

const actions = {
  [PREFECTURE_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/prefectures', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('PREFECTURE_GET_ALL_SUCCESS', {
            prefectures: data.data.prefectures,
            prefecturePagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('PREFECTURE_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [PREFECTURE_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.prefectures = params.prefectures
    state.prefecturePagination = params.prefecturePagination
  },
  [PREFECTURE_GET_ALL_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
