var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "21",
      "height": "23",
      "viewBox": "0 0 21 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.05295 5.57493C2.48133 5.57493 1.97655 5.58264 1.47199 5.57267C0.900604 5.56157 0.534026 5.22467 0.535159 4.73167C0.536292 4.2355 0.899471 3.89769 1.47449 3.89543C3.09826 3.88818 4.72203 3.89271 6.4131 3.89271C6.4131 3.34035 6.40879 2.8222 6.414 2.30428C6.42488 1.22675 7.11159 0.537772 8.19252 0.533693C9.80157 0.52735 11.4111 0.526217 13.0206 0.534147C14.1278 0.539358 14.807 1.2306 14.8188 2.34506C14.824 2.83376 14.8197 3.32245 14.8197 3.89248C15.0211 3.89248 15.2046 3.89248 15.3881 3.89248C16.7876 3.89248 18.1871 3.89158 19.5861 3.89294C20.2993 3.89384 20.6865 4.18543 20.6949 4.72125C20.7033 5.25911 20.3084 5.57154 19.6113 5.57448C19.1584 5.57675 18.7052 5.57493 18.1776 5.57493C18.1776 5.80376 18.1776 6.00563 18.1776 6.20772C18.1776 10.6506 18.1783 15.0937 18.1771 19.5371C18.1771 21.3845 17.1764 22.3773 15.3168 22.3777C12.1857 22.3784 9.05436 22.3784 5.92327 22.3777C4.03487 22.3773 3.0534 21.3992 3.05182 19.4949C3.04796 15.0867 3.05204 10.6787 3.05295 6.27048C3.05295 6.06272 3.05295 5.85519 3.05295 5.57493ZM4.76304 5.58762C4.75081 5.70294 4.73721 5.77001 4.73699 5.83729C4.73585 10.4753 4.73336 15.1128 4.73789 19.7507C4.73857 20.388 5.06913 20.6916 5.73182 20.6921C8.96985 20.6946 12.2074 20.6946 15.4452 20.6914C16.1974 20.6905 16.4906 20.396 16.491 19.6402C16.4938 15.125 16.4926 10.6096 16.4929 6.09467C16.4929 5.92883 16.4929 5.76321 16.4929 5.58785C12.5434 5.58762 8.67782 5.58762 4.76304 5.58762ZM13.1064 3.87028C13.1064 3.29413 13.1064 2.77712 13.1064 2.24492C11.4312 2.24492 9.78435 2.24492 8.13248 2.24492C8.13248 2.80566 8.13248 3.33469 8.13248 3.87028C9.80406 3.87028 11.4389 3.87028 13.1064 3.87028Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.7761 13.1675C9.7761 14.2337 9.78018 15.2999 9.77474 16.3661C9.77157 16.9484 9.45665 17.319 8.96976 17.3358C8.46158 17.3537 8.0975 16.9699 8.09591 16.379C8.09002 14.2117 8.09002 12.0442 8.09455 9.87668C8.09569 9.30257 8.43077 8.93599 8.92513 8.93372C9.41949 8.93169 9.76817 9.29826 9.77293 9.86376C9.78244 10.9651 9.77565 12.0662 9.7761 13.1675Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.4542 13.1327C11.4542 12.0669 11.4498 11.0012 11.4557 9.93541C11.4594 9.3135 11.7736 8.94352 12.2845 8.9365C12.7997 8.92948 13.133 9.29945 13.1346 9.9123C13.1393 12.0612 13.1393 14.2102 13.1343 16.3594C13.133 16.9654 12.7904 17.3433 12.2807 17.3343C11.7743 17.3252 11.4585 16.9493 11.4555 16.3301C11.4505 15.2642 11.4542 14.1984 11.4542 13.1327Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }