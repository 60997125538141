<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M65.607,89.165c3.175-0.565,5.639-2.044,7.125-4.274c1.525-2.289,1.944-5.237,1.212-8.525
		c-1.075-4.825-3.584-8.825-6.51-13.184c-0.982-1.464-2.161-2.088-3.943-2.088c-3.426,0.03-6.781-0.021-10.139-0.021L40.549,61
		h-3.357h-5.889c-4.028,0-8.058,0.062-12.086,0.073c-1.344,0.004-2.245,0.321-2.754,0.911c-0.494,0.57-0.646,1.477-0.467,2.736
		c0.12,0.836,0.21,1.685,0.3,2.526c0.053,0.495,0.105,0.994,0.164,1.488l0.726,6.098c0.556,4.686,1.112,9.373,1.692,14.056
		c0.354,2.856,0.725,5.713,1.095,8.567c0.5,3.853,1,7.705,1.46,11.563c0.362,3.053,0.997,3.63,3.996,3.632l15.566-0.007
		l11.404,0.009c1.899,0,3.8-0.002,5.7-0.009c2.135-0.008,3.145-0.803,3.376-2.658l0.364-2.922c0.649-5.207,1.32-10.592,1.912-15.895
		C63.896,89.877,64.348,89.39,65.607,89.165z M66.334,70.463l0.559,1.024c1.622,2.976,2.682,5.539,2.577,8.382
		c-0.092,2.446-1.767,4.051-4.371,4.187l-0.446,0.023L66.334,70.463z M56.837,108H26.102l-4.629-37.646l0.725,0.487
		c9.521,7.785,21.311,16.039,36.56,18.621l0.354,0.245L56.837,108z M59.678,84.592l-0.39-0.016
		c-14.31-2.593-25.241-10.51-34.047-17.831L24.444,66h37.526L59.678,84.592z"
      />
      <path
        d="M112.755,108.2c-3.658-10.365-5.308-19.021-5.189-27.238c0.021-1.368-0.224-4.705-2.922-6.131
		c-2.886-1.53-2.973-3.772-2.804-6.129c0.039-0.551,0.028-1.123,0.018-1.676c-0.005-0.265-0.01-0.529-0.01-0.794
		c-0.002-4.673-0.278-4.962-4.933-5.159c-0.168-0.008-0.292-0.087-0.423-0.17c-0.058-0.036-0.128-0.081-0.22-0.131l-0.194-0.107
		L96.07,19.079c0-1.844-0.432-4.041-2.488-4.041c-2.078,0.012-2.51,2.157-2.51,4.002L91.068,61h-1.822
		c-3.161,0-3.935,0.878-3.942,4.044c-0.001,0.63,0.003,1.29,0.007,1.92c0.011,1.525,0.021,3.117-0.052,4.67
		c-0.029,0.659-0.469,1.593-1.078,1.921c-3.014,1.623-4.494,4.239-4.658,8.224c-0.027,0.671-0.044,1.349-0.06,2.025
		c-0.054,2.26-0.109,4.598-0.598,6.817c-1.178,5.355-2.661,10.735-4.095,15.938l-0.548,1.988c-0.38,1.386-0.317,2.427,0.188,3.094
		c0.494,0.654,1.447,0.991,2.832,1.003c0.663,0.006,1.325,0.008,1.988,0.008l4.278,0.157c1.114,0,2.229,0.191,3.342,0.191h0.106
		c1.538,0,2.581-0.75,3.382-2.06c0.597-0.979,1.242-2.01,1.927-3.014c0.324-0.477,0.659-1.009,1.002-1.522l0.311-0.488l0.313,0.456
		c0.348,0.519,0.681,0.999,1.001,1.467c0.7,1.023,1.362,2.154,1.956,3.14c0.774,1.285,1.817,2.021,3.384,2.021h0.115
		c1.209,0,2.418-0.02,3.628-0.02l4.748,0.02h0.853c1.706,0,2.777-0.525,3.275-1.23C113.345,111.069,113.313,109.783,112.755,108.2z
		 M90,66h3.286c0.696,0,1.379,0.066,2.063,0.07c0.214,0,0.511,0.051,0.786,0.074L97,66.237V72h-7V66z M107.067,106.924
		c-0.004,0.084-0.067,0.192-0.067,0.336V108h-0.258c-0.493,0-0.98-0.176-1.463-0.168c-0.477,0.007-0.947-0.078-1.415-0.078
		c-0.869,0-1.572-0.07-2.214-0.124c-0.694-0.061-1.459-0.676-1.857-1.229c-0.791-1.101-1.549-2.261-2.281-3.372
		c-0.522-0.792-1.044-1.59-1.581-2.37c-1.484-2.146-3.25-2.139-4.734,0.026l-0.597,0.863c-1.015,1.472-2.064,2.993-2.979,4.55
		c-0.641,1.09-1.448,1.574-2.619,1.574c-0.118,0-0.239-0.004-0.365-0.014c-0.956-0.072-1.92,0.126-2.91,0.145
		c-0.446,0.007-0.897,0.196-1.357,0.196H79.88l0.126-0.654c0.287-1.072,0.597-2.218,0.903-3.264
		c0.688-2.341,1.337-4.598,1.709-6.888c0.777-4.787,1.271-9.732,1.75-14.493l0.146-1.455c0.258-2.525,1.328-3.564,3.695-3.572
		c3.647-0.016,7.296-0.024,10.947,0.001c2.138,0.014,3.392,1.192,3.438,3.235c0.211,9.354,1.652,17.724,4.468,25.59
		C107.11,106.638,107.075,106.764,107.067,106.924z"
      />
      <path
        d="M44.197,54.575c5.938-0.002,11.056-5.043,11.064-11.007c0.012-6.202-4.74-11.15-10.876-11.266l-0.167-0.002
		c-5.89,0-10.854,5.09-10.854,11.115c-0.001,2.978,0.96,5.78,3.042,7.89C38.486,53.413,41,54.575,44,54.575v0.375l0,0L44.197,54.575
		z M44.349,49.586c-1.716-0.006-3.305-0.676-4.499-1.887c-1.158-1.175-1.787-2.854-1.771-4.464C38.114,39.82,40.889,37,44.395,37
		h0.018c1.705,0,3.297,0.813,4.482,2.015c1.149,1.164,1.774,2.772,1.758,4.393C50.618,46.831,47.849,49.586,44.349,49.586z"
      />
      <path
        d="M64.534,31.491c0.111,0.006,0.239,0.009,0.366,0.009c4.086,0,7.78-3.648,8.068-7.966c0.13-1.943-0.631-3.986-2.089-5.607
		c-1.584-1.762-3.769-2.822-5.995-2.91c-0.09-0.003-0.179-0.005-0.268-0.005c-4.193,0-8.035,3.84-8.059,8.056
		C56.533,27.701,59.664,31.007,64.534,31.491z M62.386,20.898c0.594-0.596,1.438-0.924,2.375-0.924l0.128,0.002
		c1.901,0.056,3.153,1.406,3.115,3.357c-0.038,1.874-1.383,3.182-3.271,3.182c-1.929-0.014-3.257-1.354-3.26-3.258
		C61.471,22.329,61.795,21.49,62.386,20.898z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WorkIcon'
}
</script>

<style scoped></style>
