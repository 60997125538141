import axios from 'axios'
import {
  VISIT_MEMO_GET_ALL,
  VISIT_MEMO_GET_ALL_SUCCESS,
  VISIT_MEMO_GET_ALL_ERROR,
  VISIT_MEMO_GET_BY_ID,
  VISIT_MEMO_CREATE,
  VISIT_MEMO_EDIT,
  VISIT_MEMO_DELETE
} from '@/store/actions/visitMemo'

function initialState() {
  return {
    status: '',
    visitMemos: [],
    visitMemosPagination: {},
    visitMemo: [],
    customerVisitMemosCount: 0
  }
}

const state = initialState()

const getters = {
  allVisitMemos: state => state.visitMemos,
  visitMemosPagination: state => state.visitMemosPagination,
  visitMemoById: state => state.visitMemo,
  customerVisitMemosCount: state => state.customerVisitMemosCount
}

const actions = {
  [VISIT_MEMO_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/visit-memo', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_ALL_SUCCESS', {
            visitMemos: data.data.visit_memos,
            visitMemosPagination: data.paginate,
            visitMemosCount: data.data.visits_count
          })

          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_GET_BY_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/visit-memo/${params}`)
        .then(response => {
          let data = response.data
          commit('VISIT_MEMO_GET_BY_ID', {
            visitMemo: data.data.visit_memo
          })
          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/visit-memo', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('VISIT_MEMO_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [VISIT_MEMO_EDIT]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/visit-memo/${data.visit_id}`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  [VISIT_MEMO_DELETE]: ({ commit }, { data, visit_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/visit-memo/` + visit_id, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [VISIT_MEMO_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.visitMemos = params.visitMemos
    state.visitMemosPagination = params.visitMemosPagination
    state.customerVisitMemosCount = params.visitMemosCount
  },
  [VISIT_MEMO_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [VISIT_MEMO_GET_BY_ID]: (state, params) => {
    state.status = 'success'
    state.visitMemo = params.visitMemo
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
