<template>
  <v-dialog
    persistent
    max-width="400"
    :value="getModalVisibility"
    @keydown.esc="hideModal"
  >
    <v-card>
      <v-card-title class="headline">
        {{ getModalTitle }}
      </v-card-title>

      <v-card-text>
        {{ getModalText }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getModalAction.cancel.color"
          text
          @click="hideModal"
          :disabled="getModalLoading"
        >
          {{ getModalAction.cancel.text }}
        </v-btn>
        <v-btn
          text
          :color="getModalAction.process.color"
          v-if="getModalAction.function !== null"
          :loading="getModalLoading"
          @click="actionExecuted()"
        >
          {{ getModalAction.process.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Action',
  computed: {
    ...mapGetters([
      'getModalTitle',
      'getModalText',
      'getModalVisibility',
      'getModalAction',
      'getModalLoading'
    ])
  },
  methods: {
    ...mapMutations(['hideModal', 'startModalLoading']),
    actionExecuted() {
      this.getModalAction.function()
      this.startModalLoading()
    }
  }
}
</script>

<style scoped></style>
