<template>
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4.29053" cy="3.5" r="3.5" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'DotIcon'
}
</script>

<style scoped></style>
