var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.1",
      "id": "レイヤー_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "width": "128px",
      "height": "128px",
      "viewBox": "0 0 128 128",
      "enable-background": "new 0 0 128 128",
      "xml:space": "preserve",
      "fill": "currentColor"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M98.528,19H64.54H30.553c-3.113,0-4.063,1.025-4.063,4.132l-0.002,43.729c0,8.154-0.001,16.239,0.003,24.393\n\t\tC26.492,93.994,27.55,95,30.265,95h1.576l7.286,0.082c2.474,0,5.729,0.051,8.976,0.101c0.979,0.016,2.172,0.536,2.902,1.236\n\t\tc2.581,2.476,5.144,5.064,7.622,7.557c0.885,0.89,1.77,1.784,2.656,2.671c1.402,1.401,2.368,2.002,3.228,2.002\n\t\tc0.849,0,1.79-0.577,3.148-1.935c0.899-0.899,1.796-1.802,2.692-2.703c2.466-2.481,5.015-5.046,7.587-7.505\n\t\tc0.769-0.733,2.022-1.258,3.05-1.275c3.213-0.056,6.41-0.151,8.836-0.151L97.391,95h1.261c2.848,0,3.857-0.938,3.858-3.82\n\t\tc0.003-22.717,0.003-45.469,0-68.187C102.51,20.033,101.505,19,98.528,19z M96,89h-0.165c-1.392,0-2.777,0.049-4.16,0.046\n\t\tc-3.039-0.009-6.062,0.011-9.081,0.041l-0.891,0.019c-0.755,0-1.394-0.004-1.942-0.013c-0.408-0.006-0.766-0.008-1.084-0.008\n\t\tc-2.059,0-2.188,0.135-4.367,2.365c-0.509,0.521-1.121,1.149-1.887,1.903c-1.686,1.662-3.318,3.335-5.046,5.104\n\t\tc-0.789,0.808-1.588,1.626-2.405,2.456l-0.34,0.346l-0.857-0.787c-0.31-0.281-0.588-0.533-0.853-0.797l-2.186-2.177\n\t\tc-2.318-2.307-4.797-4.613-7.084-6.95C52.658,89.534,52,89.082,50,89.082v-0.498l0,0l0.094,0.498\n\t\tc-2.566,0.026-4.897,0.034-7.477,0.034L33,89.105V25h63V89z"
    }
  }), _c('g', [_c('circle', {
    attrs: {
      "cx": "64.083",
      "cy": "43.168",
      "r": "2.917"
    }
  }), _c('path', {
    attrs: {
      "d": "M67,71c0,1.657-1.343,3-3,3l0,0c-1.657,0-3-1.343-3-3V53c0-1.657,1.343-3,3-3l0,0c1.657,0,3,1.343,3,3V71z"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }