export const WORKTIMES = 'WORKTIMES'
export const CREATE_NEW_WORKTIME = 'CREATE_NEW_WORKTIME'
export const UPDATE_WORKTIME = 'UPDATE_WORKTIME'
export const DELETE_WORKTIME = 'DELETE_WORKTIME'
export const MONTHLY_WORKTIME = 'MONTHLY_WORKTIME'
export const WORKTIME_REST = 'WORKTIME_REST'
export const WORKTIME_OPERATIONAL_HOURS = 'WORKTIME_OPERATIONAL_HOURS'
export const WORKTIME_OPERATIONAL_HOURS_GET = 'WORKTIME_OPERATIONAL_HOURS_GET'
export const WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS = 'WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS'
export const WORKTIME_OPERATIONAL_HOURS_GET_ERROR = 'WORKTIME_OPERATIONAL_HOURS_GET_ERROR'
export const WORKTIME_OPERATIONAL_HOURS_CREATE = 'WORKTIME_OPERATIONAL_HOURS_CREATE'
export const WORKTIME_OPERATIONAL_HOURS_CREATE_SUCCESS = 'WORKTIME_OPERATIONAL_HOURS_CREATE_SUCCESS'
export const WORKTIME_OPERATIONAL_HOURS_CREATE_ERROR = 'WORKTIME_OPERATIONAL_HOURS_CREATE_ERROR'
export const WORKTIME_OPERATIONAL_HOURS_UPDATE = 'WORKTIME_OPERATIONAL_HOURS_UPDATE'
export const WORKTIME_OPERATIONAL_HOURS_UPDATE_SUCCESS = 'WORKTIME_OPERATIONAL_HOURS_UPDATE_SUCCESS'
export const WORKTIME_OPERATIONAL_HOURS_UPDATE_ERROR = 'WORKTIME_OPERATIONAL_HOURS_UPDATE_ERROR'
export const WORKTIME_OPERATIONAL_HOURS_DELETE = 'WORKTIME_OPERATIONAL_HOURS_DELETE'
export const DETAIL_WORKTIMES = 'DETAIL_WORKTIMES'
export const DETAIL_WORKTIMES_SUCCESS = 'DETAIL_WORKTIMES_SUCCESS'