import AuthAPI from '@/services/auth'
import axios from 'axios'

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT,
  AUTH_RESET
} from '@/store/actions/auth'
import router from '@/router/index.js'

function initialState() {
  return {
    token: '',
    isLoggedIn: '',
    status: '',
    user: null
  }
}

const state = {
  token: '',
  status: '',
  isLoggedIn: '',
  user: null
}

const getters = {
  token: state => state.token,
  isLoggedIn: state => !!state.token,
  user: state => state.user
}

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      AuthAPI.login(user)
        .then(response => {
          const user = response.data.data.user
          const token = response.data.data.access_token
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          commit('AUTH_SUCCESS', { token, user })
          resolve(response)
        })
        .catch(err => {
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },

  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios.post('logout')
        .then(resp => {
          if (resp.status == 204) {
            commit('AUTH_LOGOUT')
          }
          resolve()
        })
        .catch(err => {
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [AUTH_FORGOT]: ({ commit, dispatch }, email) => {
    return new Promise((resolve, reject) => {
      AuthAPI.sendResetLink(email)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [AUTH_RESET]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      AuthAPI.reset(user)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },

  [AUTH_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.token = params.token
    state.user = params.user
    state.user.full_name = params.user.last_name + ' ' + params.user.first_name
  },

  [AUTH_ERROR]: state => {
    state.status = 'error'
  },

  [AUTH_LOGOUT]: state => {
    state.token = ''
    router.push({ name: 'Login' }).catch(() => {})

    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    window.localStorage.clear()
    state.status = ''
    state.user = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
