var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.1",
      "id": "レイヤー_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "width": "128px",
      "height": "128px",
      "viewBox": "0 0 128 128",
      "enable-background": "new 0 0 128 128",
      "xml:space": "preserve",
      "fill": "currentColor"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M103.18,101.118c-0.164-16.146-10.466-30.712-25.635-36.248c-0.542-0.197-1.082-0.407-1.67-0.636l-1.888-0.728l0.871-0.529\n\t\tc12.51-7.598,14.017-19.647,11.506-27.658c-2.922-9.321-11.627-16.041-21.169-16.339c-0.264-0.009-0.525-0.013-0.787-0.013\n\t\tc-10.048,0-18.708,6.019-22.063,15.334C38.485,45.011,41.88,53.87,53.351,63.027l0.594,0.474l-0.669,0.357\n\t\tc-0.1,0.054-0.193,0.108-0.285,0.161c-0.222,0.129-0.431,0.248-0.652,0.329c-17.906,6.638-27.315,20.288-27.208,39.372\n\t\tc0.024,4.346,0.932,5.279,5.389,5.279h64.91c3.737,0,5.898-0.102,6.833-1.047C103.193,107.014,103.218,104.818,103.18,101.118z\n\t\t M47.865,42.399c-0.034-4.385,1.643-8.475,4.722-11.598C55.642,27.704,59.704,26,64.028,26h0.104\n\t\tc8.976,0,16.299,7.234,16.326,16.18c0.013,4.343-1.682,8.422-4.772,11.525c-3.091,3.105-7.185,4.807-11.527,4.813\n\t\tC55.233,58.518,47.934,51.28,47.865,42.399z M96.135,101.432L96.112,102H32.186l-0.02-0.572c-0.316-7.963,2.745-15.95,8.622-22.065\n\t\tC46.974,72.926,55.525,69,64.251,69h0.178c8.628,0,17.101,4.025,23.244,10.501C93.5,85.643,96.505,93.582,96.135,101.432z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }