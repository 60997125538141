<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.131,13.591c2.213,0.472,4.464,0.813,6.634,1.437
	c16.403,4.713,27.085,20.646,25.354,37.64c-0.544,5.343-2.199,10.289-4.944,14.88c-8.774,14.675-17.557,29.346-26.354,44.007
	c-0.466,0.777-1.015,1.555-1.687,2.149c-1.186,1.05-2.636,0.969-3.779-0.117c-0.486-0.462-0.908-1.015-1.263-1.586
	C51.87,97.173,42.654,82.343,33.459,67.5c-2.847-4.595-4.418-9.628-4.981-14.991c-0.027-0.258-0.149-0.506-0.227-0.759
	c0-1.92,0-3.84,0-5.76c0.074-0.213,0.194-0.421,0.218-0.639c1.629-15.023,12.663-27.453,27.39-30.813
	c1.663-0.379,3.354-0.635,5.032-0.947C62.971,13.591,65.051,13.591,67.131,13.591z M64.305,104.929
	c0.251-0.371,0.417-0.594,0.56-0.832c8.073-13.508,16.154-27.012,24.213-40.528c3.919-6.572,4.805-13.642,3.126-21.028
	C89.53,30.771,80.279,22.263,68.041,20.171c-10.96-1.873-23.001,3.629-29.012,13.582c-5.97,9.885-6.095,20.147-0.247,30.021
	c8.018,13.538,16.513,26.795,24.806,40.17C63.772,104.242,64,104.514,64.305,104.929z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.892,49.096c0.013-9.768,8.252-18.024,17.947-17.983
	c9.772,0.042,17.933,8.233,17.932,17.999c-0.001,9.762-8.194,17.896-18.008,17.878C54.038,66.973,45.88,58.804,45.892,49.096z
	 M63.895,37.591c-6.524-0.006-11.562,5.014-11.571,11.534c-0.01,6.362,5.18,11.513,11.551,11.464
	c6.398-0.049,11.41-5.071,11.417-11.441C75.298,42.646,70.319,37.597,63.895,37.591z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon'
}
</script>

<style scoped></style>
