<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    focusable="false"
    width="1em"
    height="1em"
    style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckCircleFilledIcon'
}
</script>
