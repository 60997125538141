import axios from 'axios'
import {
  GET_PHOTO,
  CREATE_PHOTO,
  UPDATE_PHOTO,
  GET_PHOTO_SUCCESS,
  GET_PHOTO_ERROR,
  GET_ALL_PHOTO,
  GET_ALL_PHOTO_BY_CUSTOMER_ID,
  GET_ALL_PHOTO_SUCCESS,
  GET_ALL_PHOTO_ERROR,
  DELETE_PHOTO
} from '@/store/actions/photo'

function initialState() {
  return {
    status: '',
    photos: [],
    photoPagination: {},
    photosCount: 0,
    photoLoading: false
  }
}

const state = initialState()

const getters = {
  photos: state => state.photos,
  photoPagination: state => state.photoPagination,
  photo: state => state.photo,
  photosCount: state => state.photosCount,
  photoLoading: state => state.photoLoading
}

const actions = {
  [GET_ALL_PHOTO]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/photos', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_PHOTO_SUCCESS', {
            photos: data.data.photos,
            photoPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_PHOTO_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [GET_ALL_PHOTO_BY_CUSTOMER_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('togglePhotoLoading')
      axios
        .get('/photos/' + params.customer_id + '/by-customer-id', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_PHOTO_SUCCESS', {
            photos: data.data.photos,
            photoPagination: data.paginate,
            photosCount: data.data.photos_count
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_PHOTO_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          commit('togglePhotoLoading')
        })
    })
  },

  [CREATE_PHOTO]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/photos', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          let data = response.data
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          commit('GET_PHOTO_SUCCESS', {
            photo: data.photo
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_PHOTO_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [UPDATE_PHOTO]: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/photos/' + id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          let data = response.data
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          commit('GET_PHOTO_SUCCESS', {
            photo: data.photo
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_PHOTO_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [DELETE_PHOTO]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/photos/' + id)
        .then(response => {
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          resolve(response)
        })
        .catch(error => {
          commit('GET_PHOTO_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  togglePhotoLoading: (state) => {
    state.photoLoading = !state.photoLoading
  },
  [GET_PHOTO_ERROR]: state => {
    state.status = 'error'
  },
  [GET_PHOTO_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.photo = params.photo
  },
  [GET_ALL_PHOTO_ERROR]: state => {
    state.status = 'error'
  },
  [GET_ALL_PHOTO_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.photos = params.photos
    state.photoPagination = params.photoPagination
    state.photosCount = params.photosCount
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
