<template>
  <svg
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
  >
    <path
      d="M72.687,49.938c-0.688-0.006-1.377-0.007-2.11-0.007l-4.914,0.006c-0.813,0-1.544-0.491-1.852-1.243
	c-0.307-0.752-0.129-1.614,0.45-2.184c1.334-1.311,2.611-2.527,3.834-3.692c2.889-2.751,5.383-5.127,7.6-7.745
	c0.902-1.064,1.475-3.203,1.25-4.671c-0.222-1.461-2.263-1.679-3.459-1.679c-1.351,0.006-2.645,0.008-3.939,0.008l-8.709-0.01
	c-2.382,0-4.765,0.007-7.146,0.031c-1.152,0.012-3.839,0.352-3.88,3.24c-0.038,2.83,2.557,3.31,4.107,3.343
	c1.508,0.036,3.011,0.025,4.52,0.02c0.669-0.004,1.338-0.008,2.006-0.008c0.813,0,1.544,0.491,1.852,1.243l0.388,0.935
	c0.312,0.758,0.13,1.629-0.46,2.199l-3.018,2.925c-2.413,2.343-4.908,4.765-7.398,7.119c-2.169,2.055-2.263,3.573-1.734,4.906
	C50.588,55.981,51.483,57,54.355,57h0.188c2.361,0,4.725-0.246,7.088-0.246l8.474-0.095l2.44-0.056
	c4.88-0.014,4.913-2.397,4.926-3.284c0.014-0.927-0.225-1.641-0.727-2.154C75.984,50.387,74.581,49.954,72.687,49.938z"
      fill="currentColor"
    />
    <path
      d="M41.639,92.365c-1.526-0.038-3.057-0.027-4.585-0.017c-0.722,0.005-1.443,0.01-2.165,0.01c-0.822,0-1.561-0.503-1.861-1.269
	l-0.382-0.976c-0.299-0.757-0.109-1.618,0.478-2.181c1.076-1.029,2.169-2.043,3.263-3.057c2.706-2.509,5.504-5.103,7.925-7.875
	c0.714-0.819,1.148-3.002,0.785-3.957c-0.223-0.563-1.974-1.69-3.153-1.752c-2.908-0.148-6.011-0.219-9.765-0.219
	c-2.775,0-5.552,0.038-8.327,0.076l-2.225,0.029c-2.208,0.028-3.563,1.213-3.622,3.168c-0.061,2.032,1.319,3.301,3.69,3.394
	c1.571,0.059,3.146,0.044,4.72,0.029c0.721-0.007,1.442-0.014,2.163-0.014c0.737,0,1.416,0.406,1.764,1.057l0.477,0.893
	c0.415,0.785,0.262,1.749-0.375,2.368c-1.055,1.025-2.13,2.034-3.205,3.043c-2.617,2.456-5.323,4.995-7.635,7.714
	c-0.914,1.076-1.354,3.338-1.015,4.24c0.244,0.559,2.133,1.682,3.488,1.759c2.689,0.151,5.595,0.222,9.141,0.222
	c2.3,0,4.603-0.029,6.903-0.058c1.19-0.015,2.38-0.03,3.568-0.041c1.444-0.015,3.882-0.447,3.965-3.183
	c0.028-0.936-0.225-1.691-0.751-2.248C44.222,92.803,43.093,92.402,41.639,92.365z"
      fill="currentColor"
    />
    <path
      d="M105.752,92.382c-1.62-0.072-3.252-0.052-4.883-0.034c-0.755,0.009-1.51,0.017-2.265,0.017
	c-0.748,0-1.434-0.418-1.777-1.082l-0.456-0.88c-0.416-0.787-0.261-1.755,0.38-2.373c1.075-1.038,2.169-2.061,3.263-3.083
	c2.663-2.49,5.417-5.064,7.809-7.81c0.824-0.945,1.278-3.102,0.964-3.852c-0.304-0.732-2.18-1.922-3.403-1.988
	c-2.89-0.159-5.947-0.233-9.625-0.233c-2.973,0-6.004,0.05-8.936,0.098l-1.745,0.028c-2.106,0.031-3.465,1.315-3.461,3.271
	c0.005,1.921,1.362,3.176,3.543,3.274c1.577,0.072,3.163,0.051,4.746,0.034c0.735-0.009,1.471-0.017,2.206-0.017
	c0.638,0,1.237,0.304,1.614,0.818l0.648,0.888c0.599,0.807,0.505,1.933-0.218,2.63c-1.073,1.035-2.163,2.055-3.254,3.073
	c-2.667,2.493-5.425,5.071-7.817,7.817c-0.826,0.948-1.283,3.106-0.97,3.855c0.303,0.729,2.183,1.918,3.408,1.986
	c2.894,0.159,5.964,0.233,9.66,0.233c2.939,0,5.938-0.048,8.837-0.094l1.812-0.029c2.103-0.034,3.46-1.318,3.456-3.272
	C109.286,93.735,107.932,92.48,105.752,92.382z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'OffDay2Icon'
}
</script>

<style scoped></style>
