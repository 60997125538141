<template>
  <v-app class="pa-6">
    <Action />
    <Header />
    <Sidebar />
    <v-main class="content mt-4 transition-none">
      <v-alert v-if="alert" :type="alert.status" dismissible text>
        {{ alert.message }}
      </v-alert>
      <v-card>
        <v-card-text class="px-0 py-0">
          <router-view />
        </v-card-text>
      </v-card>
      <!-- <Footer /> -->
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/admin/partials/Header/Header'
import Sidebar from '@/components/admin/partials/Sidebar/Sidebar'
// import Footer from '@/components/admin/partials/Footer/Footer'
import './Layout.scss'
import Action from '@/components/dialog/Action'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: { Action, Header, Sidebar },
  computed: {
    ...mapState(['alert'])
  },
  updated() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  }
}
</script>

<style src="./Layout.scss" scoped lang="scss" />
