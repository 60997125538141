<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.04102 0.816406C10.413 0.825781 13.1983 3.59878 13.1799 6.96791C13.1609 10.3975 10.4396 13.121 7.02102 13.1144C3.58739 13.1082 0.873393 10.3619 0.886768 6.94816C0.899893 3.52566 3.63252 0.851656 7.04102 0.816406ZM7.04489 1.76828C4.17389 1.75391 1.84777 4.06578 1.82952 6.95028C1.81077 9.81978 4.11864 12.1473 7.00514 12.1714C9.88252 12.1949 12.2253 9.86791 12.2331 6.97791C12.2406 4.10366 9.92889 1.78153 7.04489 1.76828Z"
      fill="#909090"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50743 5.43484C7.50743 5.81084 7.51718 6.18734 7.5028 6.56259C7.49668 6.72859 7.56043 6.82596 7.68605 6.91821C8.16968 7.27271 8.65205 7.62884 9.12493 7.99709C9.23243 8.08059 9.33768 8.20184 9.37955 8.32734C9.44493 8.52359 9.3738 8.71184 9.1898 8.82696C9.0113 8.93784 8.82368 8.93321 8.65155 8.80559C8.15093 8.43409 7.67255 8.02809 7.14693 7.69634C6.67643 7.39859 6.5103 7.02996 6.54305 6.48146C6.5878 5.73996 6.5528 4.99409 6.55568 4.24984C6.5568 3.93071 6.69843 3.73334 6.93955 3.69634C7.26968 3.64534 7.49755 3.84546 7.50518 4.21034C7.51343 4.61871 7.50693 5.02671 7.50743 5.43484Z"
      fill="#909090"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon'
}
</script>

<style scoped></style>
