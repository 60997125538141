var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "27",
      "viewBox": "0 0 26 27",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "13",
      "cy": "13.5",
      "r": "13",
      "fill": "url(#paint0_radial)"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.5 20.5C16.8133 20.5 18.9261 19.4475 20.3259 17.7229C20.533 17.4678 20.3072 17.0951 19.9872 17.156C16.3484 17.849 13.0068 15.059 13.0068 11.3859C13.0068 9.26998 14.1395 7.32429 15.9804 6.27666C16.2642 6.11518 16.1928 5.68495 15.8704 5.62539C15.4183 5.54204 14.9596 5.50007 14.5 5.5C10.3601 5.5 7 8.85481 7 13C7 17.1399 10.3548 20.5 14.5 20.5Z",
      "fill": "#F2C94C"
    }
  }), _c('defs', [_c('radialGradient', {
    attrs: {
      "id": "paint0_radial",
      "cx": "0",
      "cy": "0",
      "r": "1",
      "gradientUnits": "userSpaceOnUse",
      "gradientTransform": "translate(13 13.5) rotate(90) scale(20.5)"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0.301275",
      "stop-color": "#8F8686"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#C4C4C4",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }