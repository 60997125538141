import axios from 'axios'
import {
  POST_GET_ALL,
  POST_GET_ALL_SUCCESS,
  POST_GET_ALL_ERROR,
  POST_GET,
  POST_GET_SUCCESS,
  CREATE_POST,
  POST_UPDATE,
  POST_REMOVE
} from '@/store/actions/post'

function initialState() {
  return {
    status: '',
    post: {},
    posts: [],
    postPagination: {}
  }
}

const state = initialState()

const getters = {
  allposts: state => state.posts,
  postPagination: state => state.postPagination,
  post: state => state.post
}

const actions = {
  [POST_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/posts', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('POST_GET_ALL_SUCCESS', {
            posts: data.data.posts,
            postPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('POST_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [POST_GET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/posts/' + params)
        .then(response => {
          let data = response.data
          commit('POST_GET_SUCCESS', {
            post: data.data.post
          })
          resolve(response)
        })
        .catch(error => {
          commit('POST_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [CREATE_POST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/posts', data)
        .then(response => {
          let data = response.data
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          commit('POST_GET_ALL_SUCCESS', {
            post: data.post
          })
          resolve(response)
        })
        .catch(error => {
          commit('POST_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [POST_UPDATE]: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/posts/' + id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          // let data = response.data
          // console.log(data)
          // commit('POST_GET_SUCCESS', {
          //   post: data.data.post
          // })
          resolve(response)
        })
        .catch(error => {
          commit('POST_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [POST_REMOVE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/posts/' + data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('POST_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [POST_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.posts = params.posts
    state.postPagination = params.postPagination
  },
  [POST_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [POST_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.post = params.post
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
