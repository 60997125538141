import axios from 'axios'
import {
  FLOOR_MATERIAL_GET_ALL,
  FLOOR_MATERIAL_GET_ALL_SUCCESS,
  FLOOR_MATERIAL_GET_ALL_ERROR,
  FLOOR_MATERIAL_CREATE,
  FLOOR_MATERIAL_CREATE_ERROR,
  FLOOR_MATERIAL_CREATE_SUCCESS,
  FLOOR_MATERIAL_DELETE,
  FLOOR_MATERIAL_DELETE_ERROR,
  FLOOR_MATERIAL_DELETE_SUCCESS,
  FLOOR_MATERIAL_GET,
  FLOOR_MATERIAL_GET_ERROR,
  FLOOR_MATERIAL_GET_SUCCESS,
  FLOOR_MATERIAL_UPDATE,
  FLOOR_MATERIAL_UPDATE_ERROR,
  FLOOR_MATERIAL_UPDATE_SUCCESS
} from '@/store/actions/floorMaterial'

function initialState() {
  return {
    status: '',
    floorMaterials: [],
    pagination: {},
    floorMaterial: {}
  }
}

const state = initialState()

const getters = {
  allFloorMaterials: state => state.floorMaterials,
  floorMaterialPagination: state => state.pagination,
  getFloorMaterial: state => state.floorMaterial
}

const actions = {
  [FLOOR_MATERIAL_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/floor-materials', {
          params: params
        })

        .then(response => {
          let data = response.data
          commit('FLOOR_MATERIAL_GET_ALL_SUCCESS', {
            floorMaterials: data.data.floor_materials,
            pagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          commit('FLOOR_MATERIAL_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [FLOOR_MATERIAL_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/floor-materials/${params.id}`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('FLOOR_MATERIAL_GET_SUCCESS', {
            floorMaterial: data.data.floor_material
          })
          resolve(response)
        })
        .catch(error => {
          commit('FLOOR_MATERIAL_GET_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [FLOOR_MATERIAL_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/floor-materials/${params.id}`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('FLOOR_MATERIAL_DELETE_SUCCESS', {
            floorMaterial: data.data.floor_material
          })
          resolve(response)
        })
        .catch(error => {
          commit('FLOOR_MATERIAL_DELETE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [FLOOR_MATERIAL_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/floor-materials`, params)
        .then(response => {
          let data = response.data
          commit('FLOOR_MATERIAL_CREATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('FLOOR_MATERIAL_CREATE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [FLOOR_MATERIAL_UPDATE]: ({ commit, dispatch }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/floor-materials/${id}`, data)
        .then(response => {
          let data = response.data
          commit('FLOOR_MATERIAL_UPDATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('FLOOR_MATERIAL_UPDATE_ERROR', { error: error })
          reject(error)
        })
    })
  }
}

const mutations = {
  [FLOOR_MATERIAL_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.floorMaterials = params.floorMaterials
    state.pagination = params.pagination
  },
  [FLOOR_MATERIAL_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [FLOOR_MATERIAL_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.floorMaterial = params.floorMaterial
  },
  [FLOOR_MATERIAL_GET_ERROR]: state => {
    state.status = 'error'
  },
  [FLOOR_MATERIAL_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [FLOOR_MATERIAL_DELETE_ERROR]: state => {
    state.status = 'error'
  },
  [FLOOR_MATERIAL_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [FLOOR_MATERIAL_CREATE_ERROR]: state => {
    state.status = 'error'
  },
  [FLOOR_MATERIAL_UPDATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [FLOOR_MATERIAL_UPDATE_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
