var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "pa-6"
  }, [_c('Action'), _c('Header'), _c('Sidebar'), _c('v-main', {
    staticClass: "content mt-4 transition-none"
  }, [_vm.alert ? _c('v-alert', {
    attrs: {
      "type": _vm.alert.status,
      "dismissible": "",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]) : _vm._e(), _c('v-card', [_c('v-card-text', {
    staticClass: "px-0 py-0"
  }, [_c('router-view')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }