import axios from 'axios'
import {
  CLAIM_CATEGORY_GET_ALL,
  CLAIM_CATEGORY_GET_ALL_SUCCESS,
  CLAIM_CATEGORY_GET_ALL_ERROR,
  CLAIM_CATEGORY_CREATE,
  CLAIM_CATEGORY_CREATE_ERROR,
  CLAIM_CATEGORY_CREATE_SUCCESS,
  CLAIM_CATEGORY_DELETE,
  CLAIM_CATEGORY_DELETE_ERROR,
  CLAIM_CATEGORY_DELETE_SUCCESS,
  CLAIM_CATEGORY_GET,
  CLAIM_CATEGORY_GET_ERROR,
  CLAIM_CATEGORY_GET_SUCCESS,
  CLAIM_CATEGORY_UPDATE,
  CLAIM_CATEGORY_UPDATE_ERROR,
  CLAIM_CATEGORY_UPDATE_SUCCESS,
  CLAIM_CATEGORY_SET_EDIT_DATA
} from '@/store/actions/claimCategory'

function initialState() {
  return {
    status: '',
    claimCategories: [],
    claimPagination: {},
    claimCategory: {},
    editclaimcategoryData: {}
  }
}

const state = initialState()

const getters = {
  allClaimCategories: state => state.claimCategories,
  claimPagination: state => state.claimPagination,
  getClaimCategory: state => state.claimCategory,
  editclaimcategoryData: state => state.editclaimcategoryData
}

const actions = {
  [CLAIM_CATEGORY_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/claim-categories', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORY_GET_ALL_SUCCESS', {
            claimCategories: data.data.claimCategories,
            claimPagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORY_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CLAIM_CATEGORY_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-categories/${params}`)
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORY_GET_SUCCESS', {
            claimCategory: data.data.claimCategory
          })
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORY_GET_ERROR', { error: error })
          reject(error)
        })
    })
  },

  [CLAIM_CATEGORY_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/claim-categories/${params.id}`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORY_DELETE_SUCCESS', {
            claimCategory: data.data.claimCategory
          })
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORY_DELETE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CLAIM_CATEGORY_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/claim-categories`, params)
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORY_CREATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORY_CREATE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CLAIM_CATEGORY_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim-categories/${params.id}`, params)
        .then(response => {
          let data = response.data
          commit('CLAIM_CATEGORY_UPDATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_CATEGORY_UPDATE_ERROR', { error: error })
          reject(error)
        })
    })
  }
}

const mutations = {
  [CLAIM_CATEGORY_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.claimCategories = params.claimCategories
    state.claimPagination = params.pagination
  },
  [CLAIM_CATEGORY_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_CATEGORY_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.claimCategory = params.claimCategory
  },
  [CLAIM_CATEGORY_GET_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_CATEGORY_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CLAIM_CATEGORY_DELETE_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_CATEGORY_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CLAIM_CATEGORY_CREATE_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_CATEGORY_UPDATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CLAIM_CATEGORY_UPDATE_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_CATEGORY_SET_EDIT_DATA]: (state, data) => {
    state.editclaimcategoryData = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
