var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.1",
      "id": "レイヤー_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "width": "128px",
      "height": "128px",
      "viewBox": "0 0 128 128",
      "enable-background": "new 0 0 128 128",
      "xml:space": "preserve",
      "fill": "currentColor"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M92.691,23.487c2.452,0.174,4.808,0.178,7.109,0.536\n\t\tc6.047,0.94,10.512,6.249,10.518,12.365c0.019,20.609-0.097,41.22,0.089,61.826c0.063,6.969-4.716,13.685-13.621,13.663\n\t\tc-18.746-0.044-37.492-0.015-56.238-0.047c-3.656-0.006-7.33,0.058-10.962-0.277c-6.06-0.558-10.805-5.781-10.822-11.96\n\t\tc-0.058-21.104-0.051-42.21,0.045-63.314c0.031-7.07,5.674-12.487,12.752-12.612c1.542-0.026,3.085-0.002,4.826-0.002\n\t\tc0-1.135-0.038-2.166,0.009-3.194c0.103-2.261,1.619-3.886,3.504-3.809c1.91,0.077,3.361,1.752,3.378,3.966\n\t\tc0.005,0.98-0.091,1.964-0.147,3.081c14.177,0,28.291,0,42.648,0c0-1.21-0.012-2.299,0.002-3.387\n\t\tc0.028-2.173,1.339-3.649,3.306-3.746c1.89-0.094,3.323,1.129,3.579,3.251C92.811,21.033,92.691,22.271,92.691,23.487z\n\t\t M36.255,30.724c-1.442,0-2.67-0.006-3.897,0.001c-4.611,0.028-6.617,1.969-6.621,6.526c-0.018,20.422-0.021,40.843-0.014,61.265\n\t\tc0.002,4.249,2.147,6.339,6.457,6.34c21.539,0.004,43.078,0.003,64.616,0c4.506-0.001,6.547-2.061,6.549-6.618\n\t\tc0.006-12.042,0.003-24.084,0.002-36.126c0-8.504,0.015-17.008-0.01-25.512c-0.009-3.066-1.742-5.402-4.489-5.744\n\t\tc-1.934-0.241-3.921-0.046-6.033-0.046c0,1.229,0.052,2.338-0.012,3.438c-0.118,2.066-1.688,3.573-3.594,3.528\n\t\tc-1.947-0.045-3.387-1.54-3.46-3.644c-0.038-1.095-0.008-2.191-0.008-3.315c-14.24,0-28.237,0-42.406,0\n\t\tc0,1.139,0.022,2.179-0.006,3.217c-0.057,2.208-1.546,3.77-3.541,3.749c-1.91-0.02-3.409-1.533-3.523-3.62\n\t\tC36.209,33.112,36.255,32.057,36.255,30.724z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M83.165,57.173c-2.421,0.046-4.412-1.85-4.486-4.271\n\t\tc-0.076-2.485,1.931-4.57,4.392-4.563c2.39,0.008,4.401,2,4.429,4.383C87.526,55.112,85.564,57.127,83.165,57.173z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M78.678,88.049c0.02-2.399,2.01-4.387,4.394-4.387\n\t\tc2.383-0.002,4.395,1.987,4.428,4.378c0.033,2.459-2.057,4.535-4.51,4.48C80.603,92.469,78.658,90.452,78.678,88.049z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M48.597,52.828c-0.028,2.452-1.949,4.357-4.386,4.35\n\t\tc-2.524-0.009-4.43-2-4.358-4.556c0.067-2.415,2.086-4.346,4.477-4.28C46.748,48.407,48.623,50.379,48.597,52.828z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M68.035,88.159c-0.038,2.431-2.012,4.382-4.415,4.363\n\t\tc-2.469-0.018-4.424-2.085-4.358-4.605c0.063-2.401,2.119-4.351,4.498-4.265C66.183,83.742,68.07,85.73,68.035,88.159z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M63.668,57.175c-2.429,0.01-4.377-1.906-4.408-4.337\n\t\tc-0.033-2.477,2.024-4.559,4.455-4.512c2.393,0.046,4.331,2.052,4.32,4.466C68.021,55.231,66.095,57.166,63.668,57.175z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M39.838,88.132c-0.008-2.51,2.014-4.532,4.484-4.481\n\t\tc2.396,0.049,4.294,2.044,4.273,4.488c-0.022,2.437-1.971,4.387-4.379,4.385C41.818,92.519,39.847,90.544,39.838,88.132z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M83.132,74.808c-2.456,0.026-4.403-1.845-4.453-4.276\n\t\tc-0.052-2.54,1.878-4.491,4.426-4.476c2.421,0.013,4.392,1.966,4.401,4.359C87.514,72.814,85.554,74.783,83.132,74.808z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M44.254,66.052c2.448-0.003,4.337,1.888,4.346,4.356\n\t\tc0.008,2.456-1.866,4.372-4.313,4.403c-2.458,0.031-4.408-1.845-4.439-4.27C39.815,67.978,41.701,66.057,44.254,66.052z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M63.722,74.808c-2.451,0.036-4.405-1.83-4.461-4.263\n\t\tc-0.06-2.468,1.772-4.424,4.21-4.499c2.535-0.076,4.55,1.842,4.567,4.349C68.056,72.834,66.159,74.773,63.722,74.808z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }