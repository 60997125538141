export const GET_ALL_TASK_BY_DATE_RANGE = 'GET_ALL_TASK_BY_DATE_RANGE'
export const GET_ALL_TASK_BY_DATE = 'GET_ALL_TASK_BY_DATE'
export const GET_ALL_SUCCESS_TASK_BY_DATE_RANGE =
  'GET_ALL_SUCCESS_TASK_BY_DATE_RANGE'
export const GET_ALL_ERROR_TASK_BY_DATE = 'GET_ALL_ERROR_TASK_BY_DATE'
export const GET_ALL_TASK = 'GET_ALL_TASK'
export const GET_ALL_TASK_SUCCESS = 'GET_ALL_TASK_SUCCESS'
export const CREATE_TASK = 'CREATE_TASK'
export const GET_TASK_BY_ID = 'GET_TASK_BY_ID'
export const TASK_IMAGES_BY_PROJECT_ID = 'TASK_IMAGES_BY_PROJECT_ID'
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS'
export const ADD_TASK_IMAGES = 'ADD_TASK_IMAGES'
export const GET_TASK_BY_CUSTOMER_ID = 'GET_TASK_BY_CUSTOMER_ID'
export const GET_TASK_BY_CUSTOMER_ID_SUCCESS = 'GET_TASK_BY_CUSTOMER_ID_SUCCESS'
export const GET_TASK_MEMBERS_BY_TASK_ID = 'GET_TASK_MEMBERS_BY_TASK_ID'
export const GET_TASK_MEMBERS_SUCCESS = 'GET_TASK_MEMBERS_SUCCESS'
export const EDIT_TASK = 'EDIT_TASK'
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS'
export const EDIT_TASK_ERROR = 'EDIT_TASK_ERROR'
export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR'
export const SEND_TASK_EMAIL = 'SEND_TASK_EMAIL'
