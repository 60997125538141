import axios from 'axios'
import {
  WORKTIMES,
  CREATE_NEW_WORKTIME,
  UPDATE_WORKTIME,
  DELETE_WORKTIME,
  MONTHLY_WORKTIME,
  WORKTIME_REST,
  WORKTIME_OPERATIONAL_HOURS,
  WORKTIME_OPERATIONAL_HOURS_CREATE,
  WORKTIME_OPERATIONAL_HOURS_UPDATE,
  WORKTIME_OPERATIONAL_HOURS_GET,
  WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS,
  WORKTIME_OPERATIONAL_HOURS_DELETE,
  DETAIL_WORKTIMES,
  DETAIL_WORKTIMES_SUCCESS
} from '@/store/actions/worktime'

const state = {
  worktimes: [],
  worktimesDetails: [],
  status: null,
  monthlyWorktimes: [],
  operationalHours: [],
  operationalHour: {},
  worktimePagination: {}
}

const getters = {
  allWorktimes: state => state.worktimes,
  allWorktimesDetails: state => state.worktimesDetails,
  MontlyWorktimes: state => state.monthlyWorktimes,
  operationalHours: state => state.operationalHours,
  operationalHour: state => state.operationalHour,
  worktimePagination: state => state.worktimePagination
}

const actions = {
  [WORKTIMES]: ({ commit, dispatch }, params) => {
    return new Promise(resolve => {
      axios
        .get('worktimes', {
          params: params
        })
        .then(resp => {
          if (resp.status === 200) {
            const worktimes = resp.data.data.worktimes
            const pagination = resp.data.paginate
            commit('WORKTIMES', { worktimes, pagination })
          }
          resolve(resp)
        })
    })
  },

  [DETAIL_WORKTIMES]: ({ commit, dispatch }, params) => {
    return new Promise(resolve => {
      axios
        .get('worktimes', {
          params: params
        })
        .then(resp => {
          if (resp.status === 200) {
            const worktimes = resp.data.data.worktimes
            commit('DETAIL_WORKTIMES_SUCCESS', { worktimes })
          }
          resolve(resp)
        })
    })
  },

  [MONTHLY_WORKTIME]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      axios.get('worktimes/monthly-report/list').then(resp => {
        if (resp.status === 200) {
          const monthlyWorktimes = resp.data.data.months
          commit('MONTHLY_WORKTIME', { monthlyWorktimes })
        }
        resolve(resp)
      })
    })
  },

  [WORKTIME_REST]: ({ commit, dispatch }, params) => {
    return new Promise(resolve => {
      axios.get('/worktime-rests', { params: params }).then(resp => {
        if (resp.status === 200) {
          const rests = resp.data.rests
          commit('WORKTIME_REST', { rests })
        }
        resolve(resp)
      })
    })
  },

  [CREATE_NEW_WORKTIME]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post('worktimes', params)
        .then(resp => {
          if (resp.status === 200) {
            const worktimes = resp.data.data.data
            params.worktime_id = worktimes.id

            let payload = {
              status: resp.data.status,
              message: resp.data.data.message
            }
            commit('setAlert', payload)
          }
          resolve(resp)
        })
        .catch(error => {
          let payload = {
            status: 'error',
            message: error.data.message.error
          }
          commit('setAlert', payload)
          reject(error)
        })
    })
  },

  [UPDATE_WORKTIME]: ({ commit }, { id, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/worktimes/' + id, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            const worktimes = resp.data.data.data
            params.worktime_id = worktimes.id

            let payload = {
              status: resp.data.status,
              message: resp.data.data.message
            }
            commit('setAlert', payload)
          }
          resolve(resp)
        })
        .catch(error => {
          let payload = {
            status: 'error',
            message: error.data.message.error
          }
          commit('setAlert', payload)
          reject(error)
        })
    })
  },

  [DELETE_WORKTIME]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/worktimes/' + data.id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  [WORKTIME_OPERATIONAL_HOURS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/worktimes/operational-hours', { params: params })
        .then(resp => {
          if (resp.status === 200) {
            const operationalHours = resp.data.data.data
            commit('WORKTIME_OPERATIONAL_HOURS', { operationalHours })
          }
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  [WORKTIME_OPERATIONAL_HOURS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/worktimes/operational-hours', params)
        .then(resp => {
          if (resp.status === 200) {
            let payload = {
              status: resp.data.status,
              message: resp.data.data.message
            }
            commit('setAlert', payload)
          }
          resolve(resp)
        })
        .catch(error => {
          let payload = {
            status: error.data.status,
            message: error.data.message
          }
          commit('setAlert', payload)
          reject(error)
        })
    })
  },

  [WORKTIME_OPERATIONAL_HOURS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/worktimes/operational-hours/${params.id}`, params)
        .then(resp => {
          if (resp.status === 200) {
            // let payload = {
            //   status: resp.data.status,
            //   message: resp.data.data.message
            // }
            // commit('setAlert', payload)
          }
          resolve(resp)
        })
        .catch(error => {
          let payload = {
            status: error.data.status,
            message: error.data.message
          }
          commit('setAlert', payload)
          reject(error)
        })
    })
  },

  [WORKTIME_OPERATIONAL_HOURS_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/worktimes/operational-hours/${params}`)
        .then(resp => {
          if (resp.status === 200) {
            const operationalHour = resp.data.data.operational_hour
            commit('WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS', { operationalHour })
          }
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  [WORKTIME_OPERATIONAL_HOURS_DELETE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/worktimes/operational-hours/' + data.id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [WORKTIMES]: (state, params) => {
    state.worktimes = params.worktimes
    state.worktimePagination = params.pagination
  },
  [DETAIL_WORKTIMES_SUCCESS]: (state, params) => {
    state.worktimesDetails = params.worktimes
  },
  [WORKTIME_REST]: (state, params) => {
    state.worktimeRest = params.worktimeRest
  },
  [MONTHLY_WORKTIME]: (state, params) => {
    state.monthlyWorktimes = params.monthlyWorktimes
  },
  [WORKTIME_OPERATIONAL_HOURS]: (state, params) => {
    state.operationalHours = params.operationalHours
  },
  [WORKTIME_OPERATIONAL_HOURS_GET_SUCCESS]: (state, params) => {
    state.operationalHour = params.operationalHour
  },
  DELETE_WORKTIME(state, id) {
    let index = state.worktimes.findIndex(worktime => worktime.id == id)
    state.worktimes.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
