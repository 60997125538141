import axios from 'axios'
import {
  GET_SITE_SVC,
  GET_SUCCESS_SITE_SVC,
  GET_ERROR_SITE_SVC,
  GET_SITE_BY_CUSTOMER,
  GET_SITE_BY_CUSTOMER_SUCCESS,
  GET_SITE_BY_CUSTOMER_ERROR,
  SITE_GET_ALL,
  SITE_GET_ALL_SUCCESS,
  SITE_GET_ALL_LIST,
  SITE_GET_ALL_LIST_SUCCESS,
  SITE_ADD_PDF
} from '@/store/actions/site'

function initialState() {
  return {
    sites: [],
    site_svc: [],
    list_sites: [],
    sitesPagination: {},
  }
}

const state = initialState()

const getters = {
  allSites: state => state.sites,
  listSites: state => state.list_sites,
  getSiteSvc: state => state.site_svc,
  sitesPagination: state => state.sitesPagination,
}

const actions = {
  [SITE_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/sites', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('SITE_GET_ALL_SUCCESS', {
            sites: data.data.sites
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ERROR_SITE_SVC', { error: error })

          reject(error)
        })
    })
  },

  [SITE_GET_ALL_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/sites/list', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('SITE_GET_ALL_LIST_SUCCESS', {
            sites: data.data.sites,
            sitesPagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ERROR_SITE_SVC', { error: error })

          reject(error)
        })
    })
  },

  [GET_SITE_BY_CUSTOMER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`customers/${params.id}/sites`)
        .then(response => {
          let data = response.data
          commit('SITE_GET_ALL_SUCCESS', {
            sites: data.data.sites
          })
          resolve(response)
        })
        .catch(error => {
          commit('SITE_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [GET_SITE_SVC]: ({ commit, dispatch }, { id, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/sites/' + id + '/with-svc', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_SUCCESS_SITE_SVC', {
            sites: data.data.sites
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ERROR_SITE_SVC', { error: error })

          reject(error)
        })
    })
  },

  [SITE_ADD_PDF]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/sites/add-pdf`, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('GET_ERROR_SITE_SVC', { error: error })

          reject(error)
        })
    })
  },
}

const mutations = {
  [GET_SUCCESS_SITE_SVC]: (state, params) => {
    state.status = 'success'
    state.site_svc = params.site_svc
  },
  [GET_ERROR_SITE_SVC]: state => {
    state.status = 'error'
  },
  [SITE_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.sites = params.sites
  },
  [SITE_GET_ALL_LIST_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.list_sites = params.sites
    state.sitesPagination = params.sitesPagination
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
