import axios from 'axios'
import {
  GET_ALL_TASK_BY_DATE,
  GET_ALL_TASK_BY_DATE_RANGE,
  GET_ALL_SUCCESS_TASK_BY_DATE_RANGE,
  GET_ALL_ERROR_TASK_BY_DATE,
  GET_ALL_TASK,
  GET_ALL_TASK_SUCCESS,
  CREATE_TASK,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID,
  TASK_IMAGES_BY_PROJECT_ID,
  ADD_TASK_IMAGES,
  GET_TASK_BY_CUSTOMER_ID_SUCCESS,
  GET_TASK_BY_CUSTOMER_ID,
  GET_TASK_MEMBERS_BY_TASK_ID,
  GET_TASK_MEMBERS_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_ERROR,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERROR,
  SEND_TASK_EMAIL,
} from '@/store/actions/task'

function initialState() {
  return {
    status: '',
    customerTasks: [],
    tasks: [], // this state for get task data by date
    allTasks: [],
    task: {},
    taskPagination: {},
    taskImages: [],
    taskMembers: []
  }
}

const state = initialState()

const getters = {
  allCustomerTasks: state => state.customerTasks,
  allTasks: state => state.tasks, // this is for the get task data by date
  allTasksData: state => state.allTasks,
  taskPagination: state => state.taskPagination,
  task: state => state.task,
  taskImages: state => state.taskImages,
  taskMembers: state => state.taskMembers
}

const actions = {
  [GET_ALL_TASK_BY_DATE_RANGE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('task-filter/by-range', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_SUCCESS_TASK_BY_DATE_RANGE', {
            tasks: data.data.tasks,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },

  [GET_ALL_TASK_BY_DATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('task-filter/by-date', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_SUCCESS_TASK_BY_DATE_RANGE', {
            tasks: data.data.tasks,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },

  [GET_ALL_TASK]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('tasks', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_TASK_SUCCESS', {
            tasks: data.data.tasks,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },
  [CREATE_TASK]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/tasks', data)
        .then(response => {
          let data = response.data
          commit('GET_ALL_TASK_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },
  [GET_TASK_BY_ID]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('tasks/' + id)
        .then(response => {
          let data = response.data
          commit('GET_TASK_BY_ID_SUCCESS', {
            task: data.data.task
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_TASK_SUCCESS', { error: error })

          reject(error)
        })
    })
  },
  [GET_TASK_BY_CUSTOMER_ID]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('get_tasks_by_customer_id/' + id)
        .then(response => {
          let data = response.data
          commit('GET_TASK_BY_CUSTOMER_ID_SUCCESS', {
            tasks: data.data.tasks
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_TASK_BY_CUSTOMER_ID_SUCCESS', { error: error })
          reject(error)
        })
    })
  },

  [TASK_IMAGES_BY_PROJECT_ID]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('task-images/by-project/' + id)
        .then(response => {
          let data = response.data
          commit('TASK_IMAGES_BY_PROJECT_ID', {
            taskImages: data.data.task_images
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_TASK_SUCCESS', { error: error })
          reject(error)
        })
    })
  },
  [ADD_TASK_IMAGES]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      axios
        .post('/task-images', data, config)
        .then(response => {
          let data = response.data
          commit('GET_ALL_TASK_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },
  [GET_TASK_MEMBERS_BY_TASK_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('task-members', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_TASK_MEMBERS_SUCCESS', {
            taskMembers: data.data.task_members
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_TASK_BY_DATE', { error: error })

          reject(error)
        })
    })
  },
  [EDIT_TASK]: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/tasks/${id}`, data)
        .then(response => {
          let data = response.data
          commit('EDIT_TASK_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('EDIT_TASK_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [DELETE_TASK]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/tasks/${id}`)
        .then(response => {
          let data = response.data
          commit('DELETE_TASK_SUCCESS', {
            message: data.data.message
          })
          resolve(response)
        })
        .catch(error => {
          commit('DELETE_TASK_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [SEND_TASK_EMAIL]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/tasks/${id}/send-email`)
        .then(response => {
          let payload = {
            status: response.data.status,
            message: response.data.data.message
          }
          commit('setAlert', payload)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [GET_ALL_SUCCESS_TASK_BY_DATE_RANGE]: (state, params) => {
    state.status = 'success'
    state.tasks = params.tasks
    state.taskPagination = params.pagination
  },
  [GET_ALL_TASK_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.allTasks = params.tasks
    state.taskPagination = params.pagination
  },
  [GET_TASK_BY_ID_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.task = params.task
  },

  [GET_TASK_MEMBERS_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.taskMembers = params.taskMembers
  },

  [TASK_IMAGES_BY_PROJECT_ID]: (state, params) => {
    state.status = 'success'
    state.taskImages = params.taskImages
  },

  [GET_TASK_BY_CUSTOMER_ID_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.customerTasks = params.tasks
  },
  [GET_ALL_ERROR_TASK_BY_DATE]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
