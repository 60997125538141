export const OFF_DAY_GET_ALL = 'OFF_DAY_GET_ALL'
export const OFF_DAY_GET_ALL_SUCCESS = 'OFF_DAY_GET_ALL_SUCCESS'
export const OFF_DAY_GET_ALL_ERROR = 'OFF_DAY_GET_ALL_ERROR'
export const OFF_DAY_CREATE = 'OFF_DAY_CREATE'
export const OFF_DAY_UPDATE = 'OFF_DAY_UPDATE'
export const OFF_DAY_REMOVE = 'OFF_DAY_REMOVE'
export const OFF_DAY_GET = 'OFF_DAY_GET'
export const SET_EDIT_DATA = 'SET_EDIT_DATA'
export const OFF_DAY_GET_SUCCESS = 'OFF_DAY_GET_SUCCESS'
export const OFF_DAY_GET_BY_FILTER = 'OFF_DAY_GET_BY_FILTER'
export const OFF_DAY_CHANGES_STATUS = 'OFF_DAY_CHANGES_STATUS'
export const OFF_DAY_EXPORT_AS_CSV = 'OFF_DAY_EXPORT_AS_CSV'
