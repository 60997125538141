export const PARTNER_GET_ALL = 'PARTNER_GET_ALL'
export const PARTNER_GET_ALL_SUCCESS = 'PARTNER_GET_ALL_SUCCESS'
export const PARTNER_GET_ALL_ERROR = 'PARTNER_GET_ALL_ERROR'
export const PARTNER_GET = 'PARTNER_GET'
export const PARTNER_GET_SUCCESS = 'PARTNER_GET_SUCCESS'
export const PARTNER_GET_ERROR = 'PARTNER_GET_ERROR'
export const PARTNER_CREATE = 'PARTNER_CREATE'
export const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS'
export const PARTNER_CREATE_ERROR = 'PARTNER_CREATE_ERROR'
export const PARTNER_EDIT = 'PARTNER_EDIT'
export const PARTNER_EDIT_SUCCESS = 'PARTNER_EDIT_SUCCESS'
export const PARTNER_EDIT_ERROR = 'PARTNER_EDIT_ERROR'
export const PARTNER_UPDATE = 'PARTNER_UPDATE'
export const PARTNER_DELETE = 'PARTNER_DELETE'
export const PARTNER_GET_ALL_WITH_TASKS = 'PARTNER_GET_ALL_WITH_TASKS'
