export const CAR_GET_ALL = 'CAR_GET_ALL'
export const CAR_GET_ALL_SUCCESS = 'CAR_GET_ALL_SUCCESS'
export const CAR_GET_ALL_ERROR = 'CAR_GET_ALL_ERROR'
export const CAR_GET = 'CAR_GET'
export const CAR_CREATE = 'CAR_CREATE'
export const CAR_UPDATE = 'CAR_UPDATE'
export const CAR_DELETE = 'CAR_DELETE'
export const CAR_GET_SUCCESS = 'CAR_GET_SUCCESS'
export const CAR_CREATE_SUCCESS = 'CAR_CREATE_SUCCESS'
export const CAR_UPDATE_SUCCESS = 'CAR_UPDATE_SUCCESS'
export const CAR_DELETE_SUCCESS = 'CAR_DELETE_SUCCESS'
export const CAR_GET_ERROR = 'CAR_GET_ERROR'
export const CAR_CREATE_ERROR = 'CAR_CREATE_ERROR'
export const CAR_UPDATE_ERROR = 'CAR_UPDATE_ERROR'
export const CAR_DELETE_ERROR = 'CAR_DELETE_ERROR'
export const CAR_GET_AVAILABILITY = 'CAR_GET_AVAILABILITY'
export const CAR_GET_AVAILABILITY_SUCCESS = 'CAR_GET_AVAILABILITY_SUCCESS'
export const CAR_GET_AVAILABILITY_ERROR = 'CAR_GET_AVAILABILITY_ERROR'
