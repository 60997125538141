<template>
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.25 7H20.625L16.3758 1.68828C16.1415 1.39554 15.8444 1.15922 15.5065 0.99681C15.1685 0.834397 14.7984 0.750047 14.4234 0.75H6.06758C5.04531 0.75 4.12617 1.37227 3.74648 2.32148L1.875 7.08828C0.798438 7.36719 0 8.33633 0 9.5V13.875C0 14.2203 0.279687 14.5 0.625 14.5H2.5C2.5 16.5711 4.17891 18.25 6.25 18.25C8.32109 18.25 10 16.5711 10 14.5H15C15 16.5711 16.6789 18.25 18.75 18.25C20.8211 18.25 22.5 16.5711 22.5 14.5H24.375C24.7203 14.5 25 14.2203 25 13.875V10.75C25 8.67891 23.3211 7 21.25 7ZM6.25 16.375C5.21602 16.375 4.375 15.534 4.375 14.5C4.375 13.466 5.21602 12.625 6.25 12.625C7.28398 12.625 8.125 13.466 8.125 14.5C8.125 15.534 7.28398 16.375 6.25 16.375ZM9.0625 7H4.56758L6.06758 3.25H9.0625V7ZM10.9375 7V3.25H14.4234L17.4234 7H10.9375ZM18.75 16.375C17.716 16.375 16.875 15.534 16.875 14.5C16.875 13.466 17.716 12.625 18.75 12.625C19.784 12.625 20.625 13.466 20.625 14.5C20.625 15.534 19.784 16.375 18.75 16.375Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CarSolidIcon'
}
</script>

<style scoped></style>
