import { render, staticRenderFns } from "./SettingIcon.vue?vue&type=template&id=325f2108&scoped=true&"
import script from "./SettingIcon.vue?vue&type=script&lang=js&"
export * from "./SettingIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325f2108",
  null
  
)

export default component.exports