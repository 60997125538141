var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22",
      "height": "24",
      "viewBox": "0 0 22 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.4731 5.29594L18.9541 2.77688C18.5879 2.41073 17.9943 2.41073 17.6282 2.77688C17.2622 3.14302 17.2621 3.73659 17.6282 4.10269L18.2249 4.69931L17.0013 5.92284C15.4086 4.59412 13.472 3.792 11.4062 3.6053V1.875H12.25C12.7678 1.875 13.1875 1.45528 13.1875 0.9375C13.1875 0.419719 12.7678 0 12.25 0H8.6875C8.16972 0 7.75 0.419719 7.75 0.9375C7.75 1.45528 8.16972 1.875 8.6875 1.875H9.53125V3.6053C4.38794 4.07011 0.25 8.38912 0.25 13.7812C0.25 19.4288 4.82036 24 10.4687 24C16.1163 24 20.6875 19.4296 20.6875 13.7812C20.6875 11.3667 19.8552 9.08034 18.3272 7.24866L19.5507 6.02512L20.1473 6.62175C20.5134 6.98784 21.107 6.98789 21.4731 6.62175C21.8392 6.25566 21.8392 5.66208 21.4731 5.29594ZM10.4687 22.125C5.86802 22.125 2.125 18.382 2.125 13.7812C2.125 9.18052 5.86802 5.4375 10.4687 5.4375C15.0695 5.4375 18.8125 9.18052 18.8125 13.7812C18.8125 18.382 15.0695 22.125 10.4687 22.125ZM15.3401 13.7812C15.3401 14.299 14.9204 14.7188 14.4026 14.7188H10.4688C9.95102 14.7188 9.5313 14.299 9.5313 13.7812V8.26523C9.5313 7.74745 9.95102 7.32773 10.4688 7.32773C10.9866 7.32773 11.4063 7.74745 11.4063 8.26523V12.8438H14.4026C14.9204 12.8438 15.3401 13.2635 15.3401 13.7812Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }