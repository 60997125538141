<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.70403 0.464478C9.3651 0.477696 9.92859 0.695103 10.3825 1.17196C10.5385 1.33621 10.6963 1.49962 10.8493 1.66696C11.6701 2.56288 11.6694 3.95479 10.8134 4.81949C8.95153 6.70035 7.07995 8.57137 5.19431 10.428C4.98604 10.6331 4.6747 10.7767 4.38614 10.8484C3.65432 11.0312 2.90873 11.1599 2.1675 11.3047C1.21546 11.4907 0.42937 10.7018 0.617667 9.7439C0.765042 8.99507 0.904964 8.24343 1.09185 7.50374C1.16062 7.23135 1.3035 6.94082 1.4984 6.74296C3.35451 4.85662 5.22792 2.98743 7.09865 1.11543C7.54275 0.671056 8.09189 0.475446 8.70403 0.464478ZM1.87204 10.059C2.66489 9.92784 3.40921 9.8076 4.15186 9.67823C4.22737 9.66515 4.30612 9.61045 4.36223 9.5542C6.20765 7.70343 8.05279 5.85309 9.89161 3.99613C10.3115 3.57173 10.3083 3.01865 9.90623 2.57188C9.77714 2.42845 9.63553 2.29612 9.49884 2.15943C8.96137 1.62281 8.38523 1.62267 7.84818 2.1607C6.16223 3.84946 4.48303 5.54526 2.78554 7.22292C2.47378 7.53103 2.26804 7.8487 2.20842 8.29181C2.13037 8.87132 1.99228 9.44254 1.87204 10.059Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.94951 13.3812C4.39603 13.3812 2.84226 13.3828 1.28892 13.3801C0.819515 13.3795 0.5415 13.0561 0.621234 12.6154C0.666656 12.3655 0.814453 12.2101 1.06434 12.1626C1.16236 12.1437 1.26431 12.1395 1.36444 12.1392C4.44652 12.1381 7.52845 12.1381 10.6101 12.1388C11.0742 12.1388 11.3537 12.3544 11.3795 12.7245C11.4044 13.0842 11.1372 13.3636 10.7362 13.3777C10.4175 13.3889 10.098 13.3809 9.77887 13.3812C8.50242 13.3812 7.22597 13.3812 5.94951 13.3812Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'WriteIcon'
}
</script>

<style scoped></style>
