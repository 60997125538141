import axios from 'axios'
import {
  CREATE_COATING_AFTER,
  UPDATE_COATING_AFTER,
  DELETE_COATING_AFTER,
  GET_ALL_COATING_AFTERS_SUCCESS,
  GET_ALL_COATING_AFTERS_ERROR
} from '@/store/actions/coatingAfter'

function initialState() {
  return {
    coatingAfters: []
  }
}

const state = initialState()

const getters = {
  coatingAfters: state => state.coatingAfters
}

const actions = {
  [CREATE_COATING_AFTER]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/after-coatings', data)
        .then(response => {
          let data = response.data
          commit('GET_ALL_COATING_AFTERS_SUCCESS', {
            coatingAfters: data.data.coatingAfters
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_COATING_AFTERS_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [UPDATE_COATING_AFTER]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch('/after-coatings/' + data.id, data)
        .then(response => {
          let data = response.data
          commit('GET_ALL_COATING_AFTERS_SUCCESS', {
            coatingAfters: data.data.coatingAfters
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_COATING_AFTERS_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [DELETE_COATING_AFTER]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/after-coatings/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [GET_ALL_COATING_AFTERS_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.coatingAfters = params.coatingAfters
  },
  [GET_ALL_COATING_AFTERS_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
