import axios from 'axios'
import { CancelToken } from 'axios'
import {
  USER_GET_ALL,
  USER_GET_ALL_SUCCESS,
  USER_GET_ALL_ERROR,
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  GET_GENERAL_USER,
  GET_GENERAL_USER_SUCCESS,
  GET_GENERAL_USER_ERROR,
  GET_DAILY_CLEANING_USER,
  GET_DAILY_CLEANING_USER_SUCCESS,
  GET_DAILY_CLEANING_USER_ERROR,
  USER_GET_ALL_WITH_TASKS,
  USER_GET_ALL_AVAILABLE_WORKER,
  USER_GET_ALL_AVAILABLE_WORKER_SUCCESS,
  USER_GET_ALL_AVAILABLE_WORKER_ERROR,
  USER_ALL,
  USER_ALL_SUCCESS,
  USER_ALL_ERROR,
  DROPDOWN_USER_LIST,
  DROPDOWN_GET_SUCCESS,
  DROPDOWN_GET_ERROR
} from '@/store/actions/user'

function initialState() {
  return {
    status: '',
    users: [],
    user: {},
    generalUser: [],
    dailyCleaningUser: [],
    availableUsers: [],
    usersList: [],
    usersPagination: {},
  }
}

const state = initialState()

const getters = {
  allUsers: state => state.users,
  userDetail: state => state.users,
  generalUser: state => state.generalUser,
  dailyCleaningUser: state => state.dailyCleaningUser,
  availableUsers: state => state.availableUsers,
  allUsersList: state => state.usersList,
  usersPagination: state => state.usersPagination,
}

const actions = {
  [USER_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('USER_GET_ALL_SUCCESS', {
            users: data.data.users,
            usersPagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ALL_ERROR', { error: error })
 
          reject(error)
        })
    })
  },
  [USER_GET_ALL_WITH_TASKS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users/tasks', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('USER_GET_ALL_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [USER_GET_ALL_AVAILABLE_WORKER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      const source = CancelToken.source()
      const timeout = setTimeout(() => {
        reject('timeout')
      }, 30000)
      axios
        .get('/users/available', {
          params: params,
          cancelToken: source.token
        })
        .then(response => {
          let data = response.data
          commit('USER_GET_ALL_AVAILABLE_WORKER_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
          clearTimeout(timeout)
        })
        .catch(error => {
          commit('USER_GET_ALL_AVAILABLE_WORKER_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [USER_GET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users/' + params)
        .then(response => {
          let data = response.data
          commit('USER_GET_SUCCESS', {
            users: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },


  [USER_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/users', data)
        .then(response => {
          let data = response.data
          commit('USER_GET_ALL_SUCCESS', {
            users: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [USER_UPDATE]: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/users/' + id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          let data = response.data
          commit('USER_GET_SUCCESS', {
            users: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [USER_DELETE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/users/' + data.id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [GET_GENERAL_USER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users?employeeType=' + params)
        .then(response => {
          let data = response.data
          commit('GET_GENERAL_USER_SUCCESS', {
            generalUser: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_GENERAL_USER_ERROR', { error: error })

          reject(error)
        })
    })
  },

  
  [DROPDOWN_USER_LIST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/drop-down-list', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('DROPDOWN_GET_SUCCESS', {
            usersList: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('DROPDOWN_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [GET_GENERAL_USER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users', {params})
        .then(response => {
          let data = response.data
          commit('GET_GENERAL_USER_SUCCESS', {
            generalUser: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_GENERAL_USER_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [USER_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users-list-dropdown', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('USER_ALL_SUCCESS', {
            user: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [GET_DAILY_CLEANING_USER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/users', {params})
        .then(response => {
          let data = response.data
          commit('GET_DAILY_CLEANING_USER_SUCCESS', {
            dailyCleaningUser: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_DAILY_CLEANING_USER_ERROR', { error: error })
          reject(error)
        })
    })
  }
}

const mutations = {
  [USER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.users = params.users
    state.usersPagination = params.usersPagination
  },

  [USER_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.users = params.users
  },

  [USER_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.users = params.users
  },
  [USER_ALL_ERROR]: (state, params) => {
    state.status = 'success'
    state.users = params.users
  },

  [USER_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [DROPDOWN_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.usersList = params.usersList
  },
  [USER_GET_ERROR]: state => {
    state.status = 'error'
  },

  [GET_GENERAL_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.generalUser = params.generalUser
  },
  [GET_GENERAL_USER_ERROR]: state => {
    state.status = 'error'
  },

  [GET_DAILY_CLEANING_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.dailyCleaningUser = params.dailyCleaningUser
  },
  [GET_DAILY_CLEANING_USER_ERROR]: state => {
    state.status = 'error'
  },

  [USER_GET_ALL_AVAILABLE_WORKER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.availableUsers = params.users
  },
  [USER_GET_ALL_AVAILABLE_WORKER_ERROR]: state => {
    state.status = 'error'
  },
  [DROPDOWN_GET_ERROR]: state=> {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
