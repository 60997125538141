var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1.36149 5.01548C1.74728 5.01548 2.09575 4.78384 2.24498 4.42798C2.86112 2.95999 4.30993 1.92542 5.99894 1.92542C6.92984 1.92542 7.78328 2.24449 8.4675 2.7737L7.93121 2.85672C7.74802 2.88512 7.60551 3.03045 7.58069 3.21414C7.55581 3.39772 7.65477 3.57584 7.8237 3.65175L10.7729 4.97856C10.9022 5.03661 11.052 5.02545 11.1711 4.94888C11.2901 4.87219 11.3623 4.74057 11.3631 4.59895L11.3784 1.34933C11.3795 1.16446 11.259 1.00097 11.0823 0.947084C10.9053 0.893196 10.7142 0.961591 10.6118 1.11547L10.2053 1.7264C9.12148 0.659516 7.6364 0 5.99894 0C3.51611 0 1.38144 1.51621 0.471001 3.6712C0.344555 3.9699 0.376425 4.31193 0.555453 4.58216C0.734482 4.85248 1.0371 5.01548 1.36149 5.01548Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.4441 7.4177C11.2651 7.14735 10.9624 6.98438 10.6381 6.98438C10.2523 6.98438 9.9038 7.21601 9.75458 7.57188C9.13841 9.03986 7.68961 10.0744 6.00062 10.0744C5.06969 10.0744 4.21625 9.75536 3.532 9.22616L4.06833 9.14318C4.25149 9.11479 4.394 8.96943 4.41887 8.78572C4.44372 8.60216 4.34479 8.42402 4.17586 8.34811L1.22663 7.02129C1.09733 6.96327 0.947583 6.97438 0.828403 7.05097C0.709469 7.12767 0.637183 7.25928 0.63642 7.40088L0.6211 10.6505C0.620066 10.8354 0.740551 10.9989 0.91724 11.0527C1.0942 11.1066 1.28539 11.0382 1.38775 10.8843L1.79425 10.2734C2.87803 11.3403 4.36314 11.9998 6.0006 11.9998C8.48347 11.9998 10.6181 10.4836 11.5285 8.32865C11.655 8.02995 11.6231 7.68795 11.4441 7.4177Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }