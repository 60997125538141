var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.5925 10.875H12.375V10.125H13.2147C13.1855 9.5 13.1307 8.66263 13.1247 8.45963C13.1064 7.83388 12.7372 7.40225 11.83 6.47425C11.7231 6.36475 11.6105 6.23463 11.4926 6.1125C11.2081 5.81813 11.0504 5.54513 10.9236 5.31938C10.6881 4.899 10.5997 4.75 9.77974 4.75H5.73374C5.66574 4.75 5.55999 4.80975 5.42862 4.94562C4.72374 5.6745 4.51449 8.25 4.53912 10.125H5.37499V10.875H4.22724C3.92587 10.875 3.68899 10.6431 3.68387 10.349C3.66699 9.84025 3.56487 5.56687 4.80799 4.2815C5.07049 4.01012 5.39062 3.875 5.73374 3.875H9.77987C11.0104 3.875 11.3422 4.2875 11.6767 4.88375C11.7895 5.085 11.9064 5.2885 12.1132 5.5025C12.2301 5.62325 12.3414 5.73487 12.4474 5.84325C13.3574 6.77412 13.9586 7.38787 13.9875 8.37562C13.9937 8.58362 14.0604 9.69638 14.0947 10.2561L14.0956 10.2854C14.0956 10.7484 13.7794 10.875 13.5925 10.875Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.375 10.125H7.375V10.875H10.375V10.125Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.32033 11.8802C5.4987 11.8802 4.83008 11.2116 4.83008 10.3898C4.83008 9.56822 5.4987 8.8996 6.32033 8.8996C7.14208 8.8996 7.8107 9.56822 7.8107 10.3898C7.81058 11.2116 7.14208 11.8802 6.32033 11.8802ZM6.32033 9.76272C5.97458 9.76272 5.6932 10.0442 5.6932 10.39C5.6932 10.7358 5.97458 11.0172 6.32033 11.0172C6.6662 11.0172 6.94745 10.7358 6.94745 10.39C6.94745 10.0442 6.66608 9.76272 6.32033 9.76272Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.2835 11.8802C10.4618 11.8802 9.79321 11.2116 9.79321 10.3898C9.79321 9.56822 10.4618 8.8996 11.2835 8.8996C12.1052 8.8996 12.7738 9.56822 12.7738 10.3898C12.7737 11.2116 12.1052 11.8802 11.2835 11.8802ZM11.2835 9.76272C10.9377 9.76272 10.6563 10.0442 10.6563 10.39C10.6563 10.7358 10.9377 11.0172 11.2835 11.0172C11.6295 11.0172 11.9107 10.7358 11.9107 10.39C11.9106 10.0442 11.6293 9.76272 11.2835 9.76272Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.35643 8.4952C5.35168 8.4952 5.34693 8.49508 5.34231 8.49495L1.99756 8.38708C1.75931 8.37933 1.57243 8.18008 1.58006 7.9417C1.58781 7.70358 1.78343 7.5167 2.02531 7.5242L5.37006 7.6322C5.60831 7.63995 5.79518 7.8392 5.78756 8.07745C5.77993 8.31083 5.58831 8.4952 5.35643 8.4952Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.78779 6.76868C5.78204 6.76868 5.77616 6.76855 5.77029 6.76843L3.07291 6.66055C2.83479 6.65093 2.64941 6.45018 2.65879 6.21205C2.66841 5.9738 2.86966 5.78893 3.10741 5.79805L5.80479 5.90593C6.04291 5.91555 6.22816 6.1163 6.21866 6.35443C6.20954 6.58668 6.01816 6.76868 5.78779 6.76868Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.6859 7.8757C9.17115 7.87558 8.65578 7.8832 8.1409 7.8727C7.84065 7.8667 7.70128 7.74145 7.6939 7.44583C7.67953 6.8577 7.68053 6.26895 7.69478 5.6807C7.7014 5.40495 7.83578 5.26108 8.10465 5.25595C8.79265 5.24283 9.48228 5.23483 10.1685 5.2717C10.3375 5.2807 10.5503 5.43333 10.6504 5.5822C10.9943 6.0912 11.3064 6.62245 11.6123 7.15558C11.8337 7.54133 11.6577 7.85608 11.2109 7.8717C10.7032 7.88958 10.1945 7.87595 9.6859 7.8757ZM10.4158 6.93495C10.2648 6.68758 10.1334 6.46533 9.99353 6.24858C9.96828 6.20958 9.9084 6.1702 9.86403 6.16958C9.45328 6.1637 9.0424 6.16633 8.62528 6.16633C8.62528 6.4372 8.62528 6.68095 8.62528 6.93495C9.21428 6.93495 9.79315 6.93495 10.4158 6.93495Z",
      "fill": "currentColor"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "fill": "white"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }