<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46731 2.91544L6.65487 0.103008C6.58923 0.0373359 6.49963 0 6.40625 0H1.48438C0.90282 0 0.429688 0.473133 0.429688 1.05469V10.9453C0.429688 11.5269 0.90282 12 1.48438 12H8.51562C9.09718 12 9.57031 11.5269 9.57031 10.9453V3.16406C9.57031 3.0682 9.53007 2.97818 9.46731 2.91544ZM6.75781 1.2003L8.37001 2.8125H7.10938C6.91552 2.8125 6.75781 2.65479 6.75781 2.46094V1.2003ZM8.51562 11.2969H1.48438C1.29052 11.2969 1.13281 11.1392 1.13281 10.9453V1.05469C1.13281 0.860836 1.29052 0.703125 1.48438 0.703125H6.05469V2.46094C6.05469 3.04249 6.52782 3.51562 7.10938 3.51562H8.86719V10.9453C8.86719 11.1392 8.70948 11.2969 8.51562 11.2969Z"
      fill="#4F55A7"
    />
    <path
      d="M7.10938 4.96875H2.89062C2.69647 4.96875 2.53906 5.12616 2.53906 5.32031C2.53906 5.51447 2.69647 5.67188 2.89062 5.67188H7.10938C7.30353 5.67188 7.46094 5.51447 7.46094 5.32031C7.46094 5.12616 7.30353 4.96875 7.10938 4.96875Z"
      fill="#4F55A7"
    />
    <path
      d="M7.10938 6.375H2.89062C2.69647 6.375 2.53906 6.53241 2.53906 6.72656C2.53906 6.92072 2.69647 7.07812 2.89062 7.07812H7.10938C7.30353 7.07812 7.46094 6.92072 7.46094 6.72656C7.46094 6.53241 7.30353 6.375 7.10938 6.375Z"
      fill="#4F55A7"
    />
    <path
      d="M7.10938 7.78125H2.89062C2.69647 7.78125 2.53906 7.93866 2.53906 8.13281C2.53906 8.32697 2.69647 8.48438 2.89062 8.48438H7.10938C7.30353 8.48438 7.46094 8.32697 7.46094 8.13281C7.46094 7.93866 7.30353 7.78125 7.10938 7.78125Z"
      fill="#4F55A7"
    />
    <path
      d="M5.70312 9.1875H2.89062C2.69647 9.1875 2.53906 9.34491 2.53906 9.53906C2.53906 9.73322 2.69647 9.89062 2.89062 9.89062H5.70312C5.89728 9.89062 6.05469 9.73322 6.05469 9.53906C6.05469 9.34491 5.89728 9.1875 5.70312 9.1875Z"
      fill="#4F55A7"
    />
  </svg>
</template>

<script>
export default {
  name: 'CSVIcon'
}
</script>

<style scoped></style>
