import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import config from '@/config'
import icons from '@/icons'
import ja from 'vuetify/lib/locale/ja'
ja.timePicker.am = '午前'
ja.timePicker.pm = '午後'

Vue.use(Vuetify)
const opts = {
  lang: {
    locales: { ja },
    current: 'ja'
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
    values: icons
  },
  theme: {
    theme: {
      themes: {
        light: config.light
      }
    }
  }
}

export default new Vuetify(opts)
