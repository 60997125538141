<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.89385V1.3125C0 0.587617 0.587617 0 1.3125 0H6.89385C7.24195 1.82021e-06 7.57579 0.138284 7.82193 0.384426L13.6156 6.17808C14.1281 6.69064 14.1281 7.52167 13.6156 8.03423L8.03423 13.6156C7.52167 14.1281 6.69064 14.1281 6.17808 13.6156L0.384426 7.82193C0.138284 7.57579 1.82021e-06 7.24195 0 6.89385H0ZM3.0625 1.75C2.33762 1.75 1.75 2.33762 1.75 3.0625C1.75 3.78738 2.33762 4.375 3.0625 4.375C3.78738 4.375 4.375 3.78738 4.375 3.0625C4.375 2.33762 3.78738 1.75 3.0625 1.75Z"
      fill="#9B2F3C"
    />
  </svg>
</template>

<script>
export default {
  name: 'TagIcon'
}
</script>

<style scoped></style>
