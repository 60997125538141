<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M111.018,58.988c-2.575-24.328-22.927-42.675-47.34-42.675c-1.723,0-3.472,0.095-5.196,0.28
		c-24.153,2.6-42.626,22.994-42.969,47.439c0.173,1.122,0.322,2.332,0.472,3.542c0.313,2.533,0.636,5.153,1.236,7.647
		c5.183,21.512,24.253,36.536,46.375,36.536c7.877,0,15.454-1.913,22.52-5.687c1.321-0.706,2.391-0.794,3.751-0.316
		c2.923,1.022,5.857,2.016,8.792,3.008c2.468,0.835,4.936,1.669,7.396,2.521c2.058,0.715,3.156,0.562,4.222-0.47
		c1.282-1.241,1.149-2.636,0.554-4.349c-0.848-2.443-1.675-4.894-2.502-7.344c-0.997-2.953-1.994-5.905-3.026-8.845
		c-0.467-1.325-0.368-2.411,0.34-3.75C110.225,77.865,112.033,68.6,111.018,58.988z M98.503,90.669
		c0.964,2.536,1.814,5.128,2.716,7.871c0.393,1.197,0.797,2.43,1.228,3.709l0.321,0.954l-0.954-0.321
		c-1.19-0.399-2.341-0.766-3.457-1.121c-2.434-0.774-4.732-1.507-6.948-2.43c-2.794-1.167-4.999-0.985-8.011,0.702
		c-6.187,3.462-12.851,5.218-19.807,5.218c-20.841,0-38.41-15.644-40.867-36.388c-1.386-11.695,2.291-23.436,10.088-32.211
		c8.347-9.394,20.604-14.492,33.208-13.761c14.335,0.823,26.968,8.92,33.792,21.658c6.83,12.748,6.568,27.769-0.7,40.181
		C97.877,86.838,97.7,88.559,98.503,90.669z"
      />
      <path
        d="M63.592,38.908c-2.021,0.051-3.27,1.487-3.274,3.66c-0.02,9.22-0.021,18.44-0.003,27.662
		c0.003,2.333,1.276,3.841,3.244,3.841l0.106-0.002c1.955-0.052,3.174-1.527,3.182-3.851c0.012-3.287,0.01-6.566,0.008-9.854
		L66.853,56h0.009l0.004-3.67c0.005-3.254,0.01-6.512-0.012-9.766C66.84,40.38,65.529,38.908,63.592,38.908z"
      />
      <circle cx="63.594" cy="85.844" r="3.313" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoChatIcon'
}
</script>

<style scoped></style>
