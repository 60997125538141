import axios from 'axios'
import {
  CLAIM_GET_ALL,
  CLAIM_GET_ALL_SUCCESS,
  CLAIM_GET_ALL_ERROR,
  CLAIMS_BY_TASK_ID,
  GET_CLAIM_CATEGORIES,
  CLAIM_CATEGORIES_GET_ALL_SUCCESS,
  CLAIM_CATEGORIES_GET_ALL_ERROR,
  CLAIM_GET_BY_ID
} from '@/store/actions/claim'

function initialState() {
  return {
    status: '',
    claims: [],
    ClaimPagination: {},
    claimsByTaskId: [],
    claim: []
  }
}

const state = initialState()

const getters = {
  allClaims: state => state.claims,
  ClaimPagination: state => state.ClaimPagination,
  claimsByTaskId: state => state.claimsByTaskId,
  claimById: state => state.claim
}

const actions = {
  [CLAIMS_BY_TASK_ID]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/claims/by-task/' + id)
        .then(response => {
          let data = response.data
          commit('CLAIMS_BY_TASK_ID', {
            claimsById: data.data.claims
          })

          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CLAIM_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/claims', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CLAIM_GET_ALL_SUCCESS', {
            claims: data.data.claims,
            ClaimPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CLAIM_GET_BY_ID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claims/${params}`)
        .then(response => {
          let data = response.data
          commit('CLAIM_GET_BY_ID', {
            claim: data.data.claim
          })
          resolve(response)
        })
        .catch(error => {
          commit('CLAIM_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [CLAIM_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.claims = params.claims
    state.ClaimPagination = params.ClaimPagination
  },
  [CLAIMS_BY_TASK_ID]: (state, params) => {
    state.status = 'success'
    state.claimsByTaskId = params.claimsById
  },
  [CLAIM_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [CLAIM_GET_BY_ID]: (state, params) => {
    state.status = 'success'
    state.claim = params.claim
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
