<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M106.769,57.566c0.844-0.683,1.715-1.388,2.521-2.127c2.604-2.388,3.226-5.85,1.585-8.818
	c-2.569-4.656-5.301-9.385-8.349-14.456c-1.33-2.215-3.521-3.485-6.01-3.485c-0.842,0-1.703,0.144-2.561,0.426
	c-0.85,0.279-1.705,0.607-2.532,0.925c-0.878,0.336-1.786,0.684-2.699,0.979c-0.714,0.231-1.436,0.349-2.144,0.349
	c-2.995,0-5.404-2.078-5.995-5.171c-0.174-0.913-0.318-1.83-0.464-2.747c-0.114-0.729-0.229-1.457-0.359-2.183
	c-0.69-3.874-3.515-6.3-7.371-6.33c-5.316-0.045-10.634-0.043-15.95-0.002c-3.866,0.03-6.697,2.449-7.391,6.314
	c-0.13,0.726-0.245,1.454-0.359,2.183c-0.146,0.917-0.29,1.833-0.464,2.745c-0.595,3.105-3.003,5.191-5.993,5.191
	c-0.705,0-1.424-0.117-2.135-0.348c-0.943-0.304-1.881-0.668-2.786-1.02c-0.691-0.269-1.383-0.538-2.082-0.783
	c-3.718-1.306-7.196-0.069-9.156,3.249c-2.663,4.51-5.338,9.155-7.948,13.808c-1.873,3.335-1.256,6.857,1.65,9.423
	c0.682,0.602,1.407,1.184,2.109,1.746c0.485,0.389,0.97,0.777,1.446,1.177c1.816,1.521,2.776,3.3,2.775,5.146
	c0,1.864-0.979,3.669-2.832,5.218c-0.438,0.365-0.882,0.724-1.326,1.081c-0.787,0.636-1.602,1.292-2.361,1.977
	c-2.705,2.441-3.321,5.877-1.606,8.965c2.522,4.551,5.222,9.225,8.253,14.29c1.377,2.302,3.53,3.568,6.066,3.568
	c0.865,0,1.755-0.15,2.646-0.447c0.811-0.271,1.61-0.578,2.41-0.885c0.918-0.352,1.867-0.716,2.822-1.021
	c0.678-0.217,1.366-0.326,2.047-0.326c2.979,0,5.382,2.038,5.982,5.073c0.2,1.017,0.36,2.04,0.521,3.063
	c0.098,0.624,0.195,1.247,0.302,1.87c0.684,3.979,3.48,6.384,7.483,6.431c0.976,0.012,1.952,0.016,2.929,0.016l4.949-0.011
	l4.927,0.01c0.983,0,1.968-0.004,2.951-0.015c3.949-0.044,6.824-2.501,7.503-6.412c0.107-0.621,0.204-1.245,0.302-1.869
	c0.157-1.005,0.32-2.045,0.523-3.063c0.608-3.047,3.008-5.094,5.971-5.094c0.678,0,1.366,0.109,2.045,0.325
	c0.99,0.313,1.973,0.694,2.923,1.062c0.648,0.251,1.296,0.503,1.95,0.734c1.031,0.365,2.044,0.55,3.01,0.55
	c2.632,0,4.807-1.331,6.29-3.851c2.667-4.524,5.309-9.113,7.852-13.641c1.943-3.456,1.332-6.946-1.677-9.575
	c-0.684-0.598-1.393-1.167-2.103-1.737c-0.337-0.27-0.673-0.54-1.007-0.813c-2.099-1.717-3.166-3.552-3.171-5.454
	c-0.005-1.883,1.032-3.697,3.081-5.391C106.098,58.109,106.434,57.838,106.769,57.566z M103.323,51.353
	c-0.845,0.702-1.682,1.397-2.51,2.102c-3.267,2.775-5.067,6.432-5.07,10.297c-0.002,3.873,1.8,7.542,5.074,10.333
	c0.872,0.743,1.753,1.474,2.684,2.245l1.367,1.137l-8.354,14.499l-4.978-1.831c-1.64-0.602-3.313-0.906-4.977-0.906
	c-6.545,0-11.861,4.686-12.927,11.395c-0.166,1.047-0.345,2.257-0.534,3.366L72.786,106H55.868l-0.03-0.506
	c-0.607-6.738-2.028-11.589-7.225-14.534c-1.985-1.124-4.01-1.711-6.187-1.711c-3.396,0-6.675,1.31-9.712,2.747l-0.312,0.137
	L23.94,77.481l1.575-1.315c0.886-0.736,1.762-1.466,2.626-2.208c3.171-2.727,4.915-6.35,4.912-10.202
	c-0.002-3.848-1.749-7.465-4.919-10.182c-0.851-0.729-1.714-1.446-2.617-2.195l-1.577-1.312l8.357-14.494l2.023,0.74
	c0.996,0.363,1.977,0.722,2.956,1.083c1.64,0.604,3.314,0.912,4.978,0.912c6.539-0.001,11.853-4.689,12.922-11.401
	c0.173-1.085,0.358-2.15,0.549-3.265L56.007,22h16.758l0.304,1.646c0.192,1.037,0.623,2.069,0.784,3.114
	C74.905,33.565,80,38.314,87,38.314v-0.002c1,0,3.133-0.311,4.799-0.92c1.029-0.376,1.937-0.758,3.016-1.16l1.737-0.669l0.128,0.273
	c1.372,2.365,2.697,4.678,4.036,6.992c1.312,2.268,2.612,4.538,4.007,6.961l0.153,0.273L103.323,51.353z"
    />
    <path
      d="M77.646,50.619C74.174,47.091,69.575,45,64.697,45h-0.134c-10.361,0-18.586,8.193-18.724,18.527
	C45.704,73.71,53.86,82.153,64.021,82.289l0.268,0.027c10.378,0,18.587-8.176,18.688-18.565
	C83.022,58.847,81.13,54.158,77.646,50.619z M72.627,71.812C70.443,73.989,67.513,75,64.373,75h-0.104
	c-6.47,0-11.509-5.085-11.473-11.587C52.833,56.952,57.936,52,64.411,52h0.034c6.482,0,11.563,5.098,11.567,11.584
	C76.014,66.721,74.812,69.632,72.627,71.812z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SettingIcon'
}
</script>

<style scoped></style>
