export const FLOOR_MATERIAL_GET_ALL = 'FLOOR_MATERIAL_GET_ALL'
export const FLOOR_MATERIAL_GET_ALL_SUCCESS = 'FLOOR_MATERIAL_GET_ALL_SUCCESS'
export const FLOOR_MATERIAL_GET_ALL_ERROR = 'FLOOR_MATERIAL_GET_ALL_ERROR'
export const FLOOR_MATERIAL_GET = 'FLOOR_MATERIAL_GET'
export const FLOOR_MATERIAL_CREATE = 'FLOOR_MATERIAL_CREATE'
export const FLOOR_MATERIAL_UPDATE = 'FLOOR_MATERIAL_UPDATE'
export const FLOOR_MATERIAL_DELETE = 'FLOOR_MATERIAL_DELETE'
export const FLOOR_MATERIAL_GET_SUCCESS = 'FLOOR_MATERIAL_GET_SUCCESS'
export const FLOOR_MATERIAL_CREATE_SUCCESS = 'FLOOR_MATERIAL_CREATE_SUCCESS'
export const FLOOR_MATERIAL_UPDATE_SUCCESS = 'FLOOR_MATERIAL_UPDATE_SUCCESS'
export const FLOOR_MATERIAL_DELETE_SUCCESS = 'FLOOR_MATERIAL_DELETE_SUCCESS'
export const FLOOR_MATERIAL_GET_ERROR = 'FLOOR_MATERIAL_GET_ERROR'
export const FLOOR_MATERIAL_CREATE_ERROR = 'FLOOR_MATERIAL_CREATE_ERROR'
export const FLOOR_MATERIAL_UPDATE_ERROR = 'FLOOR_MATERIAL_UPDATE_ERROR'
export const FLOOR_MATERIAL_DELETE_ERROR = 'FLOOR_MATERIAL_DELETE_ERROR'
