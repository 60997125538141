var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "38",
      "height": "23",
      "viewBox": "0 0 38 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.97276 19.9657C10.0483 17.3311 13.1235 14.6969 16.3026 11.9731C13.1749 9.28969 10.0861 6.6397 6.79137 3.81199C6.79137 4.48284 6.79064 4.95064 6.79137 5.41807C6.79211 6.02099 6.54903 6.48144 5.90535 6.46896C5.30279 6.45794 5.09533 6.003 5.08982 5.43753C5.07954 4.39178 5.02814 3.34346 5.70413 2.43651C6.43079 1.46346 7.40347 0.990154 8.60748 0.989786C17.1993 0.988685 25.7919 0.98024 34.3837 0.998967C36.6154 1.00411 37.9704 2.39758 37.9744 4.61429C37.9836 9.47292 37.981 14.3315 37.9759 19.1905C37.9737 21.5706 36.6397 22.9373 34.2673 22.9428C27.7339 22.9568 21.2005 22.9502 14.6679 22.9509C12.7181 22.9516 10.7691 22.9439 8.82045 22.9527C6.07866 22.9649 4.70538 20.9923 5.14968 18.1385C5.1908 17.8734 5.69348 17.4636 5.92701 17.4982C6.23765 17.5441 6.65514 17.9168 6.73593 18.2252C6.87032 18.7367 6.77301 19.3088 6.77301 19.8555C6.83984 19.8926 6.90593 19.9293 6.97276 19.9657ZM8.40039 2.74384C8.35339 2.81177 8.30565 2.8797 8.25829 2.948C8.37689 3.01262 8.51128 3.05925 8.61189 3.14444C12.6579 6.60261 16.7051 10.0582 20.7383 13.5303C21.3379 14.0462 21.8126 13.9827 22.3906 13.4855C26.3816 10.0494 30.3905 6.63199 34.3995 3.21604C34.5357 3.10038 34.7439 3.06954 34.9179 2.99867C34.8456 2.91385 34.7725 2.82829 34.6991 2.74384C25.9325 2.74384 17.1666 2.74384 8.40039 2.74384ZM8.1955 21.1521C17.1795 21.1521 25.9578 21.1521 34.8849 21.1521C31.6636 18.3904 28.5682 15.7375 25.4651 13.0769C24.7035 13.74 24.0272 14.34 23.3379 14.9253C22.1372 15.945 20.9196 15.9321 19.7167 14.8966C19.0191 14.2952 18.324 13.6901 17.6168 13.0769C14.4678 15.7753 11.3926 18.4114 8.1955 21.1521ZM26.7789 11.9724C29.9414 14.6818 33.0269 17.3249 36.2468 20.0839C36.2468 14.6055 36.2468 9.33596 36.2468 3.85091C33.028 6.61216 29.9444 9.25738 26.7789 11.9724Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.6738 13.2445C5.85431 13.2449 7.03519 13.2482 8.21496 13.2431C8.80172 13.2405 9.29155 13.4021 9.28347 14.0858C9.2754 14.7526 8.79842 14.9365 8.20761 14.9362C5.84697 14.9325 3.48669 14.9336 1.12567 14.9351C0.534132 14.9362 0.0644991 14.7456 0.0600928 14.0762C0.0553194 13.3844 0.550655 13.2405 1.13338 13.2427C2.31352 13.2478 3.49366 13.2445 4.6738 13.2445Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.89944 10.6924C5.10558 10.6921 4.31173 10.6917 3.51823 10.6924C2.97186 10.6924 2.58521 10.4347 2.57493 9.88573C2.56391 9.2942 2.96488 9.01293 3.54247 9.01073C5.13019 9.00559 6.7179 9.00485 8.30562 9.01073C8.87183 9.01293 9.28454 9.25748 9.28124 9.87105C9.27757 10.4982 8.84429 10.695 8.28102 10.6939C7.48679 10.6921 6.69294 10.6932 5.89944 10.6924Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }