import axios from 'axios'
import {
  CUSTOMER_GET_ALL,
  CUSTOMER_GET_ALL_SUCCESS,
  CUSTOMER_GET_ALL_ERROR,
  CUSTOMER_GET,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GET_ERROR,
  CUSTOMER_CREATE,
  CUSTOMER_GET_ALL_CLAIM,
  CUSTOMER_GET_ALL_CLAIM_SUCCESS,
  CUSTOMER_GET_ALL_CLAIM_ERROR,
  CUSTOMER_CREATE_CLAIM,
  CUSTOMER_EDIT_CLAIM,
  CUSTOMER_GET_ALL_SITE_SUCCESS,
  CUSTOMER_GET_ALL_SITE_ERROR,
  CUSTOMER_CREATE_SITE,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SITE,
  CUSTOMER_TASK_GET,
  CUSTOMER_TASK_GET_SUCCESS,
  CUSTOMER_TASK_GET_ERROR,
  CUSTOMER_CREATE_SERVICE_FROM_SITE,
  GET_SERVICE_TYPE,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_ERROR,
  CUROMER_TASKS_FILTER,
  CUSTOMER_DELETE_CLAIM,
  DELETE_CUSTOMER_SITE,
  DELETE_CUSTOMER,
  CUSTOMER_GET_ALL_AFTER_SERVICE,
  CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS,
  CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR,
  ACTIVITIES_GET_ALL_SUCCESS
} from '@/store/actions/customer'

function initialState() {
  return {
    status: '',
    customers: [],
    customerPagination: {},
    claims: [],
    coating_afters: [],
    customerClaimPagination: {},
    customerAfterServicePagination: {},
    customerClaimsCount: 0,
    customerCoatingAfterCount: 0,
    customerClaimLoading: false,
    customerAfterServiceLoading: false,
    customerTasks: [],
    customerTaskPagination: {},
    customerTasksCount: 0,
    customerTaskLoading: false,
    serviceTypes: [],
    searchedCustomers: [],
    selectedProject: null,
    activities: [],
    activityPagination: {}
  }
}

const state = initialState()

const getters = {
  allCustomers: state => state.customers,
  customerPagination: state => state.customerPagination,
  customer: state => state.customer,
  allCustomerclaims: state => state.claims,
  allCustomerCoatingAfters: state => state.coating_afters,
  customerClaimPagination: state => state.customerClaimPagination,
  customerAfterServicePagination: state => state.customerAfterServicePagination,
  getCustomerclaims: state => state.claim,
  customerClaimsCount: state => state.customerClaimsCount,
  customerCoatingAfterCount: state => state.customerCoatingAfterCount,
  customerClaimLoading: state => state.customerClaimLoading,
  customerAfterServiceLoading: state => state.customerAfterServiceLoading,
  customerTasksById: state => state.customerTasks,
  customerTaskPagination: state => state.customerTaskPagination,
  customerTasksCount: state => state.customerTasksCount,
  customerTaskLoading: state => state.customerTaskLoading,
  getServiceTypes: state => state.serviceTypes,
  searchedCustomers: state => state.searchedCustomers,
  selectedProject: state => state.selectedProject,
  activities: state => state.activities,
  activityPagination: state => state.activityPagination
}

const actions = {
  [CUSTOMER_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/customers', {
          params: params
        })
        .then(response => {
          let data = response.data
          if (response.data.data.activities) {
            commit('ACTIVITIES_GET_ALL_SUCCESS', {
              activities: data.data.activities,
              activityPagination: data.data.paginate,
              ...params
            })
          } else {
            commit('CUSTOMER_GET_ALL_SUCCESS', {
              customers: data.data.customers,
              customerPagination: data.paginate,
              ...params
            })
          }


          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_GET]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/customers/' + id)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            customer: data.data.customer
          })

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/customers', data)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_ALL_SUCCESS', {
            customers: data.data.customers
          })
          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_CREATE_SITE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/customers/' + data.customer_id + '/add-site', data)
        .then(response => {
          let data = response.data

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_SITE_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_CREATE_SERVICE_FROM_SITE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/customers/' + data.customer_id + '/add-service/' + data.site_id,
          data
        )
        .then(response => {
          let data = response.data

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_SITE_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_GET_ALL_CLAIM]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('startCustomerClaimLoading')
      axios
        .get('/cutomer-claims', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_ALL_CLAIM_SUCCESS', {
            claims: data.data.claims,
            customerClaimPagination: data.paginate,
            claimsCount: data.data.claims_count
          })

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_CLAIM_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          commit('stopCustomerClaimLoading')
        })
    })
  },
  [CUSTOMER_GET_ALL_AFTER_SERVICE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('startCustomerAfterServiceLoading')
      axios
        .get(`/customers/${params.customer_id}/after-services`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS', {
            coating_afters: data.data.coating_afters,
            customerAfterServicePagination: data.paginate,
            customerCoatingCount: data.data.coatingAfterCount
          })

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          commit('stopCustomerAfterServiceLoading')
        })
    })
  },

  [CUSTOMER_CREATE_CLAIM]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/customer-claims', data)
        .then(response => {
          let data = response.data
          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUSTOMER_EDIT_CLAIM]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/customer-claims/${data.claim_id}`, data)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            claims: data.data.claims,
            customerClaimPagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  [CUSTOMER_UPDATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/customers/${data.id}`, data)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            customer: data.data.customer
          })

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CUSTOMER_UPDATE_SITE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/customers/${data.id}/update-site`, data)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            customer: data.data.customer
          })

          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_GET_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CUSTOMER_TASK_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('startCustomerTaskLoading')
      axios
        .get(`/customers/tasklist`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CUSTOMER_TASK_GET_SUCCESS', {
            customerTasks: data.data.customer_tasks,
            customerTaskPagination: data.paginate,
            customerTasksCount: data.data.tasks_count
          })
          resolve(response)
        })
        .catch(error => {
          commit('CUSTOMER_TASK_GET_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          commit('stopCustomerTaskLoading')
        })
    })
  },
  [GET_SERVICE_TYPE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/customers/${id}/servicetype`)
        .then(response => {
          let data = response.data
          commit('GET_SERVICE_SUCCESS', {
            serviceTypes: data.data.service_types
          })
          resolve(response)
        })
        .catch(error => {
          commit('GET_SERVICE_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [CUROMER_TASKS_FILTER]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/customers/tasks/filtered', params)
        .then(resp => {
          if (resp.status === 200) {
            let data = resp.data

            commit('CUSTOMER_TASK_GET_SUCCESS', {
              customerTasks: data.data.customer_tasks,
              customerTaskPagination: data.paginate
            })
          }
          resolve(resp)
        })
        .catch(error => {
          commit('CUSTOMER_TASK_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [CUSTOMER_DELETE_CLAIM]: ({ commit }, { data, claim_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/customer-claims-delete/` + claim_id, data)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            claims: data.data.claims,
            customerClaimPagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  [DELETE_CUSTOMER_SITE]: ({ commit }, { customer_id, site_id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/customers/${customer_id}/delete-site/${site_id}`)
        .then(response => {
          let data = response.data
          commit('CUSTOMER_GET_SUCCESS', {
            customer: data.data.customer
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  [DELETE_CUSTOMER]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/customers/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  changeSelectedProject(state, payload) {
    state.selectedProject = payload.id
  },
  resetSelectedProject(state) {
    state.selectedProject = null
  },
  startCustomerTaskLoading(state) {
    state.customerTaskLoading = true
  },
  stopCustomerTaskLoading(state) {
    state.customerTaskLoading = false
  },
  toggleCustomerTaskLoading(state) {
    state.customerTaskLoading = !state.customerTaskLoading
  },
  startCustomerClaimLoading(state) {
    state.customerClaimLoading = true
  },
  stopCustomerClaimLoading(state) {
    state.customerClaimLoading = false
  },
  toggleCustomerClaimLoading(state) {
    state.customerClaimLoading = !state.customerClaimLoading
  },
  startCustomerAfterServiceLoading(state) {
    state.customerAfterServiceLoading = true
  },
  stopCustomerAfterServiceLoading(state) {
    state.customerAfterServiceLoading = false
  },
  toggleCustomerAfterServiceLoading(state) {
    state.customerAfterServiceLoading = !state.customerAfterServiceLoading
  },
  [CUSTOMER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    if (params.history || !params.search) {
      state.customers = params.customers
    } else {
      state.searchedCustomers = params.customers
    }
    state.customerPagination = params.customerPagination
  },
  [ACTIVITIES_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.activities = params.activities
    state.activityPagination = params.activityPagination
  },
  [CUSTOMER_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [CUSTOMER_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.customer = params.customer
  },
  [CUSTOMER_GET_ERROR]: state => {
    state.status = 'error'
  },
  [CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.coating_afters = params.coating_afters
    state.customerAfterServicePagination = params.customerAfterServicePagination
    state.customerCoatingAfterCount = params.customerCoatingCount
  },

  [CUSTOMER_GET_ALL_CLAIM_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.claims = params.claims
    state.customerClaimPagination = params.customerClaimPagination
    state.customerClaimsCount = params.claimsCount
  },

  [CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR]: state => {
    state.status = 'error'
  },
  [CUSTOMER_GET_ALL_SITE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.customers = params.customers
  },
  [CUSTOMER_GET_ALL_SITE_ERROR]: state => {
    state.status = 'error'
  },
  [CUSTOMER_TASK_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.customerTasks = params.customerTasks
    state.customerTaskPagination = params.customerTaskPagination
    state.customerTasksCount = params.customerTasksCount
  },
  [CUSTOMER_TASK_GET_ERROR]: state => {
    state.status = 'error'
  },
  [GET_SERVICE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.serviceTypes = params.serviceTypes
  },
  [GET_SERVICE_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
