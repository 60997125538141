<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.614,43.982c0,18.469,0,36.718,0,55.068c18.348,0,36.598,0,55.07,0
	c0-0.712,0-1.368,0-2.023c0-10.721-0.007-21.441,0.015-32.161c0.001-0.89,0.064-1.824,0.342-2.657
	c0.554-1.662,2.306-2.655,4.016-2.453c1.794,0.211,3.16,1.474,3.424,3.229c0.1,0.665,0.101,1.35,0.102,2.024
	c0.006,12.244,0.007,24.487,0.003,36.731c-0.002,4.026-1.244,5.27-5.24,5.27c-20.199,0.002-40.398,0.002-60.598,0
	c-3.737,0-5.09-1.352-5.09-5.085c-0.001-20.313-0.002-40.624,0-60.936c0.001-3.479,1.35-4.896,4.757-4.901
	c12.582-0.017,25.164-0.012,37.747,0.006c0.779,0.001,1.6,0.06,2.328,0.306c1.494,0.504,2.554,2.139,2.449,3.71
	c-0.115,1.722-0.942,3.033-2.634,3.568c-0.785,0.248-1.662,0.291-2.497,0.292c-10.664,0.018-21.328,0.012-31.992,0.012
	C30.142,43.982,29.468,43.982,28.614,43.982z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M93.834,28.164c-7.526,0-14.579,0.021-21.631-0.011
	c-2.761-0.013-4.432-1.818-4.181-4.37c0.214-2.154,1.856-3.468,4.433-3.471c8.691-0.011,17.383-0.005,26.074-0.005
	c1.58,0,3.162-0.034,4.741,0.011c2.436,0.069,4.064,1.625,4.072,4.049c0.033,10.441,0.032,20.883-0.003,31.324
	c-0.009,2.375-1.693,3.985-3.934,3.981c-2.238-0.004-3.869-1.607-3.886-4.009c-0.042-6.547-0.018-13.093-0.021-19.641
	c0-0.596,0-1.191,0-1.788c-0.239-0.173-0.479-0.346-0.718-0.519c-0.293,0.544-0.477,1.199-0.895,1.618
	c-12.632,12.669-25.287,25.316-37.94,37.965c-0.438,0.438-0.863,0.899-1.35,1.28c-1.664,1.303-3.776,1.185-5.229-0.256
	c-1.447-1.434-1.583-3.57-0.299-5.229c0.378-0.488,0.839-0.914,1.277-1.352c12.648-12.654,25.3-25.305,37.947-37.959
	C92.719,29.358,93.123,28.913,93.834,28.164z"
    />
  </svg>
</template>

<script>
export default {
  name: 'NewTabIcon'
}
</script>

<style scoped></style>
