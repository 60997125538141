import axios from 'axios'
import {
  CAR_GET_ALL,
  CAR_GET_ALL_SUCCESS,
  CAR_GET_ALL_ERROR,
  CAR_CREATE,
  CAR_CREATE_ERROR,
  CAR_CREATE_SUCCESS,
  CAR_DELETE,
  CAR_DELETE_ERROR,
  CAR_DELETE_SUCCESS,
  CAR_GET,
  CAR_GET_ERROR,
  CAR_GET_SUCCESS,
  CAR_UPDATE,
  CAR_UPDATE_ERROR,
  CAR_UPDATE_SUCCESS,
  CAR_GET_AVAILABILITY,
  CAR_GET_AVAILABILITY_ERROR,
  CAR_GET_AVAILABILITY_SUCCESS
} from '@/store/actions/car'

function initialState() {
  return {
    status: '',
    cars: [],
    pagination: {},
    car: {},
    availableCars: []
  }
}

const state = initialState()

const getters = {
  allCars: state => state.cars,
  pagination: state => state.pagination,
  getCar: state => state.car,
  availableCars: state => state.availableCars
}

const actions = {
  [CAR_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/cars', {
          params: params
        })

        .then(response => {
          let data = response.data
          commit('CAR_GET_ALL_SUCCESS', {
            cars: data.data.cars,
            pagination: data.paginate
          })
          resolve(response)
        })
        .catch(error => {
          commit('CAR_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CAR_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cars/${params.id}`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CAR_GET_SUCCESS', {
            car: data.data.car
          })
          resolve(response)
        })
        .catch(error => {
          commit('CAR_GET_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CAR_GET_AVAILABILITY]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cars/availability/${params.date}`)
        .then(response => {
          let data = response.data
          commit('CAR_GET_AVAILABILITY_SUCCESS', {
            cars: data.data.cars
          })
          resolve(response)
        })
        .catch(error => {
          commit('CAR_GET_AVAILABILITY_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CAR_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/cars/${params.id}`, {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('CAR_DELETE_SUCCESS', {
            car: data.data.car
          })
          resolve(response)
        })
        .catch(error => {
          commit('CAR_DELETE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CAR_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/cars`, params)
        .then(response => {
          let data = response.data
          commit('CAR_CREATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('CAR_CREATE_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [CAR_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/cars/${params.id}`, params)
        .then(response => {
          let data = response.data
          commit('CAR_UPDATE_SUCCESS', data)
          resolve(response)
        })
        .catch(error => {
          commit('CAR_UPDATE_ERROR', { error: error })
          reject(error)
        })
    })
  }
}

const mutations = {
  [CAR_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.cars = params.cars
    state.pagination = params.pagination
  },
  [CAR_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [CAR_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.car = params.car
  },
  [CAR_GET_ERROR]: state => {
    state.status = 'error'
  },
  [CAR_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CAR_DELETE_ERROR]: state => {
    state.status = 'error'
  },
  [CAR_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CAR_CREATE_ERROR]: state => {
    state.status = 'error'
  },
  [CAR_UPDATE_SUCCESS]: (state, params) => {
    state.status = 'success'
  },
  [CAR_UPDATE_ERROR]: state => {
    state.status = 'error'
  },
  [CAR_GET_AVAILABILITY_SUCCESS]: (state, params) => {
    state.availableCars = params.cars
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
