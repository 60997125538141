export const CLAIM_CATEGORY_CREATE = 'CLAIM_CATEGORY_CREATE'
export const CLAIM_CATEGORY_EDIT = 'CLAIM_CATEGORY_EDIT'
export const CLAIM_CATEGORY_EDIT_SUCCESS = 'CLAIM_CATEGORY_EDIT_SUCCESS'
export const CLAIM_CATEGORY_EDIT_ERROR = 'CLAIM_CATEGORY_EDIT_ERROR'
export const CLAIM_CATEGORY_GET_ALL = 'CLAIM_CATEGORY_GET_ALL'
export const CLAIM_CATEGORY_GET_ALL_SUCCESS = 'CLAIM_CATEGORY_GET_ALL_SUCCESS'
export const CLAIM_CATEGORY_GET_ALL_ERROR = 'CLAIM_CATEGORY_GET_ALL_ERROR'
export const CLAIM_CATEGORY_GET = 'CLAIM_CATEGORY_GET'
export const CLAIM_CATEGORY_GET_SUCCESS = 'CLAIM_CATEGORY_GET_SUCCESS'
export const CLAIM_CATEGORY_UPDATE = 'CLAIM_CATEGORY_UPDATE'
export const CLAIM_CATEGORY_DELETE = 'CLAIM_CATEGORY_DELETE'
export const CLAIM_CATEGORY_SUCCESS = 'CLAIM_CATEGORY_SUCCESS'
export const CLAIM_CATEGORY_CREATE_SUCCESS = 'CLAIM_CATEGORY_CREATE_SUCCESS'
export const CLAIM_CATEGORY_UPDATE_SUCCESS = 'CLAIM_CATEGORY_UPDATE_SUCCESS'
export const CLAIM_CATEGORY_DELETE_SUCCESS = 'CLAIM_CATEGORY_DELETE_SUCCESS'
export const CLAIM_CATEGORY_GET_ERROR = 'CLAIM_CATEGORY_GET_ERROR'
export const CLAIM_CATEGORY_CREATE_ERROR = 'CLAIM_CATEGORY_CREATE_ERROR'
export const CLAIM_CATEGORY_UPDATE_ERROR = 'CLAIM_CATEGORY_UPDATE_ERROR'
export const CLAIM_CATEGORY_DELETE_ERROR = 'CLAIM_CATEGORY_DELETE_ERROR'
export const CLAIM_CATEGORY_SET_EDIT_DATA = 'CLAIM_CATEGORY_SET_EDIT_DATA'
