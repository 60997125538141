<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M106.888,82.567l3.042-1.73c-9.84-17.302-19.852-34.489-29.797-51.695v0c-1.078-1.866-2.432-3.526-4.049-4.9
		c-1.617-1.375-3.493-2.455-5.562-3.198c-2.076-0.748-4.204-1.124-6.3-1.123c-3.243-0.003-6.4,0.903-9.157,2.592
		c-2.76,1.688-5.12,4.144-6.886,7.193C41.991,40.38,35.838,51.071,29.683,61.757c-3.674,6.381-7.611,12.759-11.121,19.497l0,0
		c-1.595,3.063-2.37,6.33-2.37,9.496c0,4.181,1.352,8.227,4.029,11.34c1.336,1.552,3.005,2.857,4.944,3.781
		c1.938,0.927,4.136,1.468,6.494,1.556l0,0c4.026,0.153,7.642,0.199,11.196,0.199c5.567,0,10.972-0.112,17.644-0.112v-3.5H57v0.098
		v3.5h3.5c0.63,0,2.685,0.003,4.555,0.003c1.07,0,2.08-0.001,2.742-0.004h-0.002c0.498-0.002,1-0.003,1.505-0.003
		c4.225,0,8.69,0.06,13.159,0.06c4.072,0,8.146-0.049,12.059-0.241c2.507-0.123,4.876-0.777,6.99-1.819
		c3.176-1.567,5.789-3.988,7.636-6.872c1.842-2.885,2.931-6.261,2.934-9.775c0.003-2.729-0.676-5.541-2.146-8.121L106.888,82.567
		l-3.043,1.729c0.854,1.507,1.229,3.065,1.232,4.662c0.009,2.724-1.171,5.582-3.173,7.744c-0.996,1.08-2.184,1.981-3.489,2.624
		c-1.308,0.643-2.728,1.032-4.241,1.106c-3.731,0.184-7.7,0.233-11.715,0.233c-4.407,0-8.87-0.06-13.159-0.06
		c-0.514,0-1.024,0.001-1.532,0.003h-0.015h0.013c-0.639,0.003-1.646,0.004-2.711,0.004c-1.865,0-3.912-0.003-4.555-0.003v3.5H64
		v-0.098v-3.5h-3.5c-6.753,0-12.176,0.113-17.644,0.112c-3.494,0-7.01-0.045-10.931-0.193l-0.206-0.008l0.206,0.008
		c-1.468-0.058-2.694-0.379-3.743-0.878c-1.566-0.752-2.771-1.916-3.638-3.428c-0.861-1.508-1.353-3.368-1.352-5.377
		c0-2.024,0.497-4.187,1.579-6.264l0,0c3.35-6.438,7.231-12.733,10.977-19.235c6.156-10.688,12.306-21.373,18.487-32.035
		c1.236-2.13,2.786-3.694,4.482-4.732c1.7-1.037,3.549-1.562,5.504-1.564c1.265,0,2.58,0.224,3.929,0.709
		c1.319,0.477,2.429,1.124,3.399,1.947c0.97,0.824,1.806,1.833,2.521,3.069l0.015,0.024l-0.015-0.024
		c9.953,17.22,19.957,34.392,29.773,51.653L106.888,82.567z"
      />
      <path
        d="M64.66,79.67c-3.8-0.04-6.62,2.7-6.631,6.44c-0.01,3.75,2.761,6.489,6.57,6.479c3.811-0.01,6.57-2.76,6.54-6.51
		C71.12,82.41,68.39,79.7,64.66,79.67z"
      />
      <path
        d="M67.67,38.996C66.64,38.975,65.61,39,64.58,39h-2.54c-3.44,0-4.44,1.096-4.069,4.535c0.639,5.9,1.289,11.774,1.97,17.675
		c0.409,3.54,0.829,7.09,1.319,10.62c0.221,1.55,1.25,2.47,2.79,2.71c1.44,0.229,2.72-0.22,3.391-1.54
		c0.449-0.89,0.63-1.95,0.75-2.95c0.489-3.84,0.909-7.7,1.35-11.54c0.58-5.18,1.19-10.34,1.729-15.52
		C71.55,40.31,70.36,39.045,67.67,38.996z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClaimIcon'
}
</script>

<style scoped></style>
