var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "21",
      "height": "22",
      "viewBox": "0 0 21 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.5 7.0625C8.33027 7.0625 6.5625 8.83027 6.5625 11C6.5625 13.1697 8.33027 14.9375 10.5 14.9375C12.6697 14.9375 14.4375 13.1697 14.4375 11C14.4375 8.83027 12.6697 7.0625 10.5 7.0625ZM20.6062 10.3643L16.7221 8.42422L18.0961 4.30625C18.2807 3.74844 17.7516 3.21934 17.1979 3.40801L13.0799 4.78203L11.1357 0.89375C10.8732 0.36875 10.1268 0.36875 9.86426 0.89375L7.92422 4.77793L3.80215 3.40391C3.24434 3.21934 2.71523 3.74844 2.90391 4.30215L4.27793 8.42012L0.39375 10.3643C-0.13125 10.6268 -0.13125 11.3732 0.39375 11.6357L4.27793 13.5758L2.90391 17.6979C2.71934 18.2557 3.24844 18.7848 3.80215 18.5961L7.92012 17.2221L9.86016 21.1062C10.1227 21.6313 10.8691 21.6313 11.1316 21.1062L13.0717 17.2221L17.1896 18.5961C17.7475 18.7807 18.2766 18.2516 18.0879 17.6979L16.7139 13.5799L20.598 11.6398C21.1313 11.3732 21.1313 10.6268 20.6062 10.3643ZM14.2119 14.7119C12.1652 16.7586 8.83477 16.7586 6.78809 14.7119C4.74141 12.6652 4.74141 9.33477 6.78809 7.28809C8.83477 5.24141 12.1652 5.24141 14.2119 7.28809C16.2586 9.33477 16.2586 12.6652 14.2119 14.7119Z",
      "fill": "#F2994A"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "21",
      "height": "21",
      "fill": "white",
      "transform": "translate(0 0.5)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }