<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.342,14.531c26.976,0.075,49.258,22.259,49.111,49.212
		c-0.152,27.437-21.922,49.225-49.271,49.172c-27.469-0.05-49.181-22.02-49.074-49.33C15.213,36.205,37.074,14.813,64.342,14.531z
		 M64.373,22.146c-22.968-0.115-41.577,18.38-41.723,41.456c-0.15,22.956,18.313,41.576,41.405,41.769
		c23.019,0.188,41.761-18.428,41.824-41.548C105.939,40.829,87.445,22.252,64.373,22.146z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.074,51.482c0,3.008,0.078,6.02-0.037,9.022
		c-0.049,1.328,0.461,2.107,1.466,2.845c3.869,2.836,7.728,5.685,11.511,8.631c0.86,0.668,1.702,1.638,2.037,2.642
		c0.523,1.57-0.046,3.076-1.518,3.997c-1.428,0.887-2.929,0.85-4.306-0.171c-4.005-2.972-7.832-6.22-12.037-8.874
		c-3.764-2.382-5.093-5.331-4.831-9.719c0.358-5.932,0.078-11.899,0.101-17.853c0.009-2.553,1.142-4.132,3.071-4.428
		c2.641-0.408,4.464,1.193,4.525,4.112C68.122,44.953,68.07,48.217,68.074,51.482z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WorkTimeIcon'
}
</script>

<style scoped></style>
