<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.65439 2.49447C5.77214 2.15435 5.87339 1.8416 5.98795 1.53354C6.32508 0.626035 7.13001 0.0573481 8.08983 0.0537856C9.32245 0.0490981 10.5553 0.0490979 11.7883 0.0539729C12.8458 0.0579105 13.6314 0.622473 13.9848 1.61941C14.2963 2.49766 14.2963 2.49635 15.2094 2.49297C15.7813 2.49072 16.3535 2.48566 16.925 2.49597C18.1359 2.51791 19.1144 3.47304 19.1197 4.6826C19.133 7.69497 19.1323 10.7072 19.1206 13.7194C19.1159 14.9454 18.1331 15.9176 16.9006 15.9193C12.2743 15.9272 7.64789 15.9277 3.0217 15.9187C1.79508 15.9159 0.818388 14.9308 0.814825 13.7045C0.8062 10.7051 0.8062 7.70547 0.816888 4.70585C0.8212 3.4766 1.79751 2.51154 3.02733 2.49616C3.89114 2.48566 4.75476 2.49447 5.65439 2.49447ZM9.9667 14.6996C12.2045 14.6996 14.4419 14.7015 16.6798 14.6985C17.5263 14.6974 17.8994 14.3323 17.9004 13.5005C17.9038 10.6145 17.9039 7.72854 17.9017 4.84235C17.9009 4.11691 17.4952 3.71904 16.7619 3.71697C15.8212 3.71435 14.8801 3.71454 13.9394 3.71941C13.5749 3.72147 13.3402 3.56622 13.2277 3.21316C13.0966 2.80216 12.9586 2.39285 12.809 1.98841C12.6281 1.49941 12.3155 1.27854 11.7984 1.27666C10.5778 1.2716 9.35714 1.2716 8.1367 1.27666C7.62314 1.27891 7.3012 1.50766 7.12551 1.98916C6.98245 2.38254 6.84651 2.7791 6.72426 3.1796C6.60632 3.56547 6.35826 3.72466 5.95907 3.7196C5.05664 3.70835 4.15364 3.71547 3.2512 3.71641C2.41476 3.71716 2.03639 4.09066 2.03601 4.91847C2.03526 7.77897 2.03508 10.6397 2.0362 13.5004C2.03639 14.3152 2.41345 14.6975 3.21595 14.6981C5.46614 14.6992 7.71651 14.6987 9.9667 14.6996Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.38919 8.91474C5.39857 6.35555 7.444 4.32117 10.0054 4.32361C12.5125 4.32586 14.5617 6.41349 14.5469 8.95055C14.5323 11.4537 12.4698 13.4873 9.95144 13.4813C7.4425 13.4755 5.37982 11.4105 5.38919 8.91474ZM13.3244 8.90367C13.3218 7.0568 11.8214 5.55211 9.97919 5.5478C8.12144 5.54405 6.60513 7.06467 6.61357 8.92317C6.62163 10.7633 8.13175 12.2603 9.979 12.2586C11.818 12.2575 13.3268 10.744 13.3244 8.90367Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0584 4.93739C16.3886 4.92783 16.6676 5.18958 16.6815 5.52183C16.695 5.84901 16.4329 6.1372 16.1051 6.1552C15.7611 6.17395 15.4641 5.89495 15.4635 5.55145C15.4628 5.21864 15.726 4.94714 16.0584 4.93739Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CameraIcon'
}
</script>

<style scoped></style>
