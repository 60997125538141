import axios from 'axios'
import {
  OFF_DAY_GET_ALL,
  OFF_DAY_GET_ALL_SUCCESS,
  OFF_DAY_GET_ALL_ERROR,
  OFF_DAY_CREATE,
  OFF_DAY_UPDATE,
  OFF_DAY_REMOVE,
  OFF_DAY_GET,
  OFF_DAY_GET_SUCCESS,
  OFF_DAY_GET_BY_FILTER,
  OFF_DAY_CHANGES_STATUS,
  OFF_DAY_EXPORT_AS_CSV,
  SET_EDIT_DATA
} from '@/store/actions/offDay'

function initialState() {
  return {
    status: '',
    offDays: [],
    offDay: {},
    editData: {},
    offDayPagination: {}
  }
}

const state = initialState()

const getters = {
  allOffDays: state => state.offDays,
  offDay: state => state.offDay,
  editData: state => state.editData,
  offDayPagination: state => state.offDayPagination
}

const actions = {
  [OFF_DAY_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/off-day-requests', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('OFF_DAY_GET_ALL_SUCCESS', {
            offDays: data.data.off_day_requests,
            offDayPagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [OFF_DAY_GET_BY_FILTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/off-day-requests/filtered', params)
        .then(response => {
          let data = response.data
          commit('OFF_DAY_GET_ALL_SUCCESS', {
            offDays: data.data.off_day_requests
          })

          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [OFF_DAY_EXPORT_AS_CSV]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/off-day-requests/export')
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },

  [OFF_DAY_CREATE]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/off-day-requests', data)
        .then(response => {
          let data = response.data
          commit('OFF_DAY_GET_ALL_SUCCESS', {
            users: data.data.users
          })
          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [OFF_DAY_REMOVE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/off-day-requests/delete/' + params.id + '/' + params.type)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [OFF_DAY_GET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/off-day-requests/' + params)
        .then(response => {
          let data = response.data
          commit('OFF_DAY_GET_SUCCESS', {
            offDay: data.data.off_day_request
          })
          resolve(response)
        })
        .catch(error => {
          commit('OFF_DAY_GET_ALL_ERROR', { error: error })
          reject(error)
        })
    })
  },
  [OFF_DAY_UPDATE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/off-day-requests/' + params.id, params)
        .then(response => {
          let data = response.data
          commit('USER_GET_SUCCESS', {
            users: data.data.user
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  },
  [OFF_DAY_CHANGES_STATUS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/off-day-requests/bulk-actions', params)
        .then(response => {
          let data = response.data
          commit('OFF_DAY_GET_ALL_SUCCESS', {
            users: data.data
          })
          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_ERROR', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [OFF_DAY_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    // state.offDays = params.offDays
    if (params.offDays != undefined) {
      state.offDays = params.offDays.map(function(element) {
        return {
          id: element.id + '-' + element.type,
          idDb: element.id,
          date: element.date,
          status: element.status,
          type: element.type,
          user: element.user
        }
      })
    }

    state.offDayPagination = params.offDayPagination
  },
  [OFF_DAY_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.offDay = params.offDay
  },
  [OFF_DAY_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [SET_EDIT_DATA]: (state, data) => {
    state.editData = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
