import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/components/admin/layout/Layout'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      title: '管理サイトログイン画面'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    props: route => ({ success: route.query.success }),
    meta: {
      public: true,
      guest: true,
      title: 'パスワードの再設定'
    }
  },
  {
    path: '/reset-password/:token/:email',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    props: route => ({ success: route.query.success }),
    meta: {
      public: true,
      guest: true,
      title: 'パスワード再設定'
    }
  },
  {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   component: () => import('@/views/dashboard/Dashboard.vue')
      //   // component: Dashboard
      // },

      {
        path: 'schedule/task-view',
        name: 'ScheduleTaskView',
        component: () => import('@/views/schedule/task-view/TaskView.vue'),
        meta: {
          title: '従業員スケジュール'
        }
      },
      {
        path: 'schedule/monthly',
        name: 'ScheduleMonthly',
        component: () => import('@/views/schedule/monthly/Monthly.vue'),
        meta: {
          title: '月間スケジュール'
        }
      },
      {
        path: 'schedule/three-days',
        name: 'ScheduleThreeDays',
        component: () => import('@/views/schedule/three-days/ThreeDays.vue'),
        meta: {
          title: '3日間スケジュール'
        }
      },

      {
        path: 'task/create',
        name: 'TaskCreate',
        component: () => import('@/views/task/Create.vue'),
        meta: {
          title: 'タスクの新規登録'
        }
      },
      {
        path: 'task/edit/:id',
        name: 'TaskEdit',
        component: () => import('@/views/task/Edit.vue'),
        meta: {
          title: 'タスク編集'
        }
      },
      {
        path: 'task/view/:id',
        name: 'TaskView',
        component: () => import('@/views/task/View.vue'),
        meta: {
          title: 'タスク詳細'
        }
      },
      {
        path: 'claim/list',
        name: 'ClaimList',
        component: () => import('@/views/claim/List.vue'),
        meta: {
          title: 'クレーム一覧'
        }
      },
      {
        path: 'claim/create',
        name: 'ClaimCreate',
        component: () => import('@/views/claim/Create.vue'),
        meta: {
          title: 'クレーム登録'
        }
      },
      {
        path: 'claim/edit/:id',
        name: 'ClaimEdit',
        props: true,
        component: () => import('@/views/claim/Edit.vue'),
        meta: {
          title: 'クレーム編集'
        }
      },
      {
        path: 'claim/detail/:id',
        name: 'ClaimDetail',
        props: true,
        component: () => import('@/views/claim/Detail.vue'),
        meta: {
          title: 'クレーム詳細'
        }
      },
      {
        path: 'customer/list',
        name: 'CustomerList',
        component: () => import('@/views/customer/List.vue'),
        meta: {
          title: '顧客一覧'
        }
      },
      {
        path: 'customer/create',
        name: 'CustomerCreate',
        component: () => import('@/views/customer/Create.vue')
      },
      {
        path: '/customer/view/',
        name: 'CustomerViewDetail',
        props: true,
        component: () => import('@/views/customer/Detail.vue'),
        children: [
          {
            path: ':customer_id/site/:site_id/basic',
            name: 'CustomerViewDetailBasic',
            props: true,
            component: () => import('@/views/customer/Detail/Basic.vue'),
            meta: {
              title: '顧客／物件／サービス詳細'
            }
          },
          {
            path: ':customer_id/site/:site_id/task',
            name: 'CustomerViewDetailTask',
            props: true,
            component: () => import('@/views/customer/Detail/Task.vue'),
            meta: {
              title: 'タスク'
            }
          },
          {
            path: ':customer_id/site/:site_id/photos',
            name: 'CustomerViewDetailPhotos',
            props: true,
            component: () => import('@/views/customer/Detail/Photos.vue'),
            meta: {
              title: '写真'
            }
          },
          {
            path: ':customer_id/site/:site_id/claim',
            name: 'CustomerViewDetailClaim',
            props: true,
            component: () => import('@/views/customer/Detail/Claim.vue'),
            meta: {
              title: 'クレーム'
            }
          },

          {
            path: ':customer_id/site/:site_id/after-service',
            name: 'CustomerViewDetailAfterService',
            props: true,
            component: () => import('@/views/customer/Detail/AfterService.vue'),
            meta: {
              title: 'アフター対応'
            }
          },

          {
            path: ':customer_id/site/:site_id/visitmemo',
            name: 'CustomerViewDetailVisitMemo',
            props: true,
            component: () => import('@/views/customer/Detail/VisitMemo.vue'),
            meta: {
              title: '訪問メモ'
            }
          }
        ]
      },
      {
        path: 'post/list',
        name: 'PostList',
        component: () => import('@/views/post/List.vue'),
        meta: {
          title: 'お知らせ一覧'
        }
      },
      {
        path: 'post/create',
        name: 'PostCreate',
        component: () => import('@/views/post/Create.vue')
      },
      {
        path: 'post/edit/:id',
        name: 'PostEdit',
        component: () => import('@/views/post/Edit.vue'),
        props: true,
        meta: {
          title: 'お知らせ編集'
        }
      },
      {
        path: 'off-day/list',
        name: 'OffDayList',
        component: () => import('@/views/off-day/List.vue'),
        meta: {
          title: '休暇管理'
        }
      },
      {
        path: 'work-time/list',
        name: 'WorkTimeList',
        component: () => import('@/views/work-time/List.vue'),
        meta: {
          title: '勤怠一覧'
        }
      },
      {
        path: 'work-time/operating-hours',
        name: 'WorkTimeOperatingHours',
        component: () => import('@/views/work-time/operating-hours/List.vue'),
        meta: {
          title: '勤怠一覧'
        }
      },
      {
        path: 'work-time/create',
        name: 'WorkTimeCreate',
        component: () => import('@/views/work-time/Create.vue'),
        meta: {
          title: '新規勤怠の作成'
        }
      },
      {
        path: 'work-time/monthly',
        name: 'WorkTimeMonthly',
        component: () => import('@/views/work-time/Monthly.vue'),
        meta: {
          title: '月間勤怠データのダウンロード'
        }
      },
      {
        path: 'work-time/:date/:user_id',
        name: 'WorkTimeDetail',
        component: () => import('@/views/work-time/Detail.vue'),
        props: true,
        meta: {
          title: '勤怠詳細'
        }
      },
      {
        path: 'work-time/report',
        name: 'WorkTimeReport',
        component: () => import('@/views/work-time/Report.vue')
      },
      {
        path: 'management/car',
        name: 'CarManagement',
        component: () => import('@/views/other/Car/List.vue')
      },
      {
        path: 'management/car/register',
        name: 'CarManagementRegister',
        component: () => import('@/views/other/Car/Create.vue')
      },
      {
        path: 'management/car/edit/:id',
        name: 'CarManagementEdit',
        component: () => import('@/views/other/Car/Edit.vue')
      },
      {
        path: 'management/floor-material',
        name: 'FloorMaterialManagement',
        component: () => import('@/views/other/floor-materials/List.vue')
      },
      {
        path: 'management/floor-material/register',
        name: 'FloorMaterialManagementRegister',
        component: () => import('@/views/other/floor-materials/Create.vue')
      },
      {
        path: 'management/floor-material/edit/:id',
        name: 'FloorMaterialManagementEdit',
        component: () => import('@/views/other/floor-materials/Edit.vue')
      },
      {
        path: 'management/partner',
        name: 'PartnerManagement',
        component: () => import('@/views/other/Partner/List.vue'),
        meta: {
          title: 'パートナー会社一覧'
        }
      },
      {
        path: 'management/partner/create',
        name: 'PartnerManagementCreate',
        component: () => import('@/views/other/Partner/Create.vue')
      },
      {
        path: 'management/partner/edit/:id',
        name: 'PartnerManagementEdit',
        props: true,
        component: () => import('@/views/other/Partner/Edit.vue')
      },
      {
        path: 'management/service',
        name: 'ServiceManagement',
        component: () => import('@/views/other/Service/List.vue')
      },
      {
        path: 'management/employee/',
        name: 'EmployeeManagement',
        component: () => import('@/views/other/employee/List.vue')
      },
      {
        path: 'management/employee/create',
        name: 'EmployeeManagementCreate',
        component: () => import('@/views/other/employee/Create.vue')
      },
      {
        path: 'management/employee/edit/:id',
        name: 'EmployeeManagementEdit',
        component: () => import('@/views/other/employee/Edit.vue'),
        props: true
      },
      {
        path: 'management/recent-updates',
        name: 'RecentUpdatesList',
        component: () => import('@/views/other/recent-updates/Index.vue')
      },
      {
        path: 'admins',
        name: 'Admins',
        component: () => import('@/views/admin/AdminList.vue')
      },
      {
        path: 'management/service/color',
        name: 'ServiceColor',
        component: () => import('@/views/other/Service/Color.vue'),
        meta: {
          title: '色設定'
        }
      },
      {
        path: 'management/category',
        name: 'Category',
        component: () => import('@/views/other/claim-category/List.vue'),
        meta: {
          title: 'Category'
        }
      }
      // {
      //   path: '/admin/create',
      //   name: 'CreateAdmin',
      //   component: () => import('@/views/admin/AdminCreate.vue')
      // },
      // {
      //   path: '/admin/edit/:id',
      //   name: 'EditAdmin',
      //   component: () => import('@/views/admin/AdminEdit.vue')
      // },
      //
      // // user
      // {
      //   path: 'users',
      //   name: 'Users',
      //   component: () => import('@/views/user/UserList.vue')
      // },
      // {
      //   path: '/user/create',
      //   name: 'CreateUser',
      //   component: () => import('@/views/user/UserCreate.vue')
      // },
      // {
      //   path: '/user/edit/:id',
      //   name: 'EditUser',
      //   component: () => import('@/views/user/UserEdit.vue')
      // },
      // // change password
      // {
      //   path: '/.well-known/change-password',
      //   redirect: { name: 'ChangePassword' }
      // },
      // {
      //   path: 'change-password',
      //   name: 'ChangePassword',
      //   component: () => import('@/views/ChangePassword.vue')
      // }
    ]
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/error/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

// Auth guard
router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some(record => record.meta.public)
  const loggedIn = store.getters.isLoggedIn
  if (authRequired && !loggedIn) {
    if (
      to.name == 'ChangePassword' &&
      to.redirectedFrom == '/.well-known/change-password'
    ) {
      next({
        path: '/forgot-password'
      })
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else if (!authRequired && loggedIn) {
    next('/schedule/task-view')
    return
  }
  next()
})

const DEFAULT_TITLE = '総合研装'
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + '｜' + DEFAULT_TITLE
      : DEFAULT_TITLE
  })
})

export default router
