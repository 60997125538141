import Vue from 'vue'
import i18n from '@/plugins/i18n'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate'
import {
  required,
  email,
  max,
  min,
  between,
  image,
  size,
  ext,
  dimensions
} from 'vee-validate/dist/rules.umd'
localize({
  jp: {
    messages: {
      required: 'この項目は必須です。'
    },
    fields: {
      claimCustomer: {
        required: i18n.t('claim_create_customer')
      },
      claimSite: {
        required: i18n.t('claim_create_site')
      },
      claimService: {
        required: i18n.t('claim_create_service')
      },
      claimTask: {
        required: i18n.t('claim_create_task')
      },
      claimCategory: {
        required: i18n.t('claim_create_category')
      },
      claimDescription: {
        required: i18n.t('claim_create_description')
      },
      claimResponse: {
        required: i18n.t('claim_create_response')
      }
    }
  }
})
localize('jp')

extend('required', {
  ...required
})

extend('max', {
  ...max,
  message: '{length}文字以下で入力してください。'
})

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters'
})

extend('email', {
  ...email,
  message: 'このメールアドレスは無効です。'
})

extend('between', {
  ...between,
  message: '{min}~{max}の数字を一つ入力してください。'
})

extend('image', {
  ...image,
  message: '{_field_}は画像ファイルを指定してください。'
})

extend('size', {
  ...size,
  message: '{_field_}は{size}KB以下のファイルを指定してください。'
})

extend('ext', {
  ...ext,
  message: '{_field_}は{extensions}のファイルを指定してください。'
})

// Added new custom rule for minimum image dimensions.
extend('min-dimensions', {
  validate: (value, args) => {
    const URL = window.URL || window.webkitURL
    return new Promise(resolve => {
      const image = new Image()
      image.onerror = () => resolve(false)
      image.onload = () => {
        resolve(image.width >= args.width && image.height >= args.height)
      }

      image.src = URL.createObjectURL(new Blob(value))
    }).then(values => {
      return values
    })
  },
  params: ['width', 'height'],
  message: '{_field_}は{width}x{height}のサイズを指定してください。'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
