export const USER_GET_ALL = 'USER_GET_ALL'
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS'
export const USER_GET_ALL_ERROR = 'USER_GET_ALL_ERROR'
export const USER_GET = 'USER_GET'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_ERROR = 'USER_GET_ERROR'
export const USER_CREATE = 'USER_CREATE'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_DELETE = 'USER_DELETE'
export const GET_GENERAL_USER = 'GET_GENERAL_USER'
export const GET_GENERAL_USER_SUCCESS = 'GET_GENERAL_USER_SUCCESS'
export const GET_GENERAL_USER_ERROR = 'GET_GENERAL_USER_ERROR'
export const GET_DAILY_CLEANING_USER = 'GET_DAILY_CLEANING_USER'
export const GET_DAILY_CLEANING_USER_SUCCESS = 'GET_DAILY_CLEANING_USER_SUCCESS'
export const GET_DAILY_CLEANING_USER_ERROR = 'GET_DAILY_CLEANING_USER_ERROR'
export const USER_GET_ALL_WITH_TASKS = 'USER_GET_ALL_WITH_TASKS'
export const USER_GET_ALL_AVAILABLE_WORKER = 'USER_GET_ALL_AVAILABLE_WORKER'
export const USER_GET_ALL_AVAILABLE_WORKER_SUCCESS =
  'USER_GET_ALL_AVAILABLE_WORKER_SUCCESS'
export const USER_GET_ALL_AVAILABLE_WORKER_ERROR =
  'USER_GET_ALL_AVAILABLE_WORKER_ERROR'
export const USER_ALL = 'USER_ALL'
export const USER_ALL_SUCCESS = 'USER_ALL_SUCCESS'
export const USER_ALL_ERROR = 'USER_ALL_ERROR'
export const DROPDOWN_USER_LIST = 'DROPDOWN_USER_LIST'
export const DROPDOWN_GET_SUCCESS = 'DROPDOWN_GET_SUCCESS'
export const DROPDOWN_GET_ERROR = 'DROPDOWN_GET_ERROR'
