export const GET_PHOTO = 'GET_PHOTO'
export const GET_ALL_PHOTO = 'GET_ALL_PHOTO'
export const CREATE_PHOTO = 'CREATE_PHOTO'
export const UPDATE_PHOTO = 'UPDATE_PHOTO'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const GET_PHOTO_SUCCESS = 'GET_PHOTO_SUCCESS'
export const GET_PHOTO_ERROR = 'GET_PHOTO_ERROR'
export const GET_ALL_PHOTO_BY_CUSTOMER_ID = 'GET_ALL_PHOTO_BY_CUSTOMER_ID'
export const GET_ALL_PHOTO_SUCCESS = 'GET_ALL_PHOTO_SUCCESS'
export const GET_ALL_PHOTO_ERROR = 'GET_ALL_PHOTO_ERROR'
