import CalendarIcon from '@/components/icons/CalendarIcon'
import ClaimIcon from '@/components/icons/ClaimIcon'
import CustomerIcon from '@/components/icons/CustomerIcon'
import WorkTimeIcon from '@/components/icons/WorkTimeIcon'
import InfoChatIcon from '@/components/icons/InfoChatIcon'
import OffDayIcon from '@/components/icons/OffDayIcon'
import SettingIcon from '@/components/icons/SettingIcon'
import NoteIcon from '@/components/icons/NoteIcon'
import NewTabIcon from '@/components/icons/NewTabIcon'
import LocationIcon from '@/components/icons/LocationIcon'
import UsersIcon from '@/components/icons/UsersIcon'
import WorkIcon from '@/components/icons/WorkIcon'
import InfoIcon from '@/components/icons/InfoIcon'
import QuestionMarkIcon from '@/components/icons/QuestionMarkIcon'
import SortDownIcon from '@/components/icons/SortDownIcon'
import SortUpIcon from '@/components/icons/SortUpIcon'
import EmployeeOffIcon from '@/components/icons/EmployeeOffIcon'
import TrashIcon from '@/components/icons/TrashIcon'
import SendMailIcon from '@/components/icons/SendMailIcon'
import CarIcon from '@/components/icons/CarIcon'
import DotIcon from '@/components/icons/DotIcon'
import CameraIcon from '@/components/icons/CameraIcon'
import WriteIcon from '@/components/icons/WriteIcon'
import OffDay2Icon from '@/components/icons/OffDay2Icon'
import UserIcon from '@/components/icons/UserIcon'
import ImagePlaceholderIcon from '@/components/icons/ImagePlaceholderIcon'
import CSVIcon from '@/components/icons/CSVIcon'
import AttendanceIcon from '@/components/icons/AttendanceIcon'
import CarSolidIcon from '@/components/icons/CarSolidIcon'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon'
import CheckCircleFilledIcon from '@/components/icons/CheckCircleFilledIcon'
import ChatIcon from '@/components/icons/ChatIcon'
import ClockIcon from '@/components/icons/ClockIcon'
import TagIcon from '@/components/icons/TagIcon'
import SunIcon from '@/components/icons/SunIcon'
import MoonIcon from '@/components/icons/MoonIcon'
import RefreshIcon from '@/components/icons/RefreshIcon'

export default {
  calendar: {
    component: CalendarIcon
  },
  claim: {
    component: ClaimIcon
  },
  customer: {
    component: CustomerIcon
  },
  workTime: {
    component: WorkTimeIcon
  },
  infoChat: {
    component: InfoChatIcon
  },
  offDay: {
    component: OffDayIcon
  },
  offDay2: {
    component: OffDay2Icon
  },
  setting: {
    component: SettingIcon
  },
  note: {
    component: NoteIcon
  },
  newTab: {
    component: NewTabIcon
  },
  location: {
    component: LocationIcon
  },
  user: {
    component: UserIcon
  },
  users: {
    component: UsersIcon
  },
  work: {
    component: WorkIcon
  },
  info: {
    component: InfoIcon
  },
  question: {
    component: QuestionMarkIcon
  },
  sortDown: {
    component: SortDownIcon
  },
  sortUp: {
    component: SortUpIcon
  },
  employeeOff: {
    component: EmployeeOffIcon
  },
  trash: {
    component: TrashIcon
  },
  sendMail: {
    component: SendMailIcon
  },
  car: {
    component: CarIcon
  },
  dot: {
    component: DotIcon
  },
  camera: {
    component: CameraIcon
  },
  write: {
    component: WriteIcon
  },
  imagePlaceholder: {
    component: ImagePlaceholderIcon
  },
  csv: {
    component: CSVIcon
  },
  attendance: {
    component: AttendanceIcon
  },
  carSolid: {
    component: CarSolidIcon
  },
  checkCircleIcon: {
    component: CheckCircleIcon
  },
  checkCircleFilledIcon: {
    component: CheckCircleFilledIcon
  },
  chat: {
    component: ChatIcon
  },
  clock: {
    component: ClockIcon
  },
  tag: {
    component: TagIcon
  },
  sun: {
    component: SunIcon
  },
  moon: {
    component: MoonIcon
  },
  refresh: {
    component: RefreshIcon
  }
}
