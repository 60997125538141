import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import car from './modules/car'
import post from './modules/post'
import claim from './modules/claim'
import offDay from './modules/offDay'
import customer from './modules/customer'
import service from './modules/service'
import partner from './modules/partner'
import user from './modules/user'
import worktime from './modules/worktime'
import prefecture from './modules/prefecture'
import task from './modules/task'
import project from './modules/project'
import sites from './modules/sites'
import photo from './modules/photo'
import coatingAfter from './modules/coatingAfter'
import claimCategory from './modules/claimCategory'
import visitMemo from './modules/visitMemo'
import floorMaterial from './modules/floorMaterial'

import auth from './modules/auth'
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    drawer: true,
    alert: null,
    modal: {
      loading: false,
      visible: false,
      title: '',
      text: '',
      action: {
        function: null,
        process: {
          color: '',
          text: ''
        },
        cancel: {
          color: '',
          text: ''
        }
      }
    }
  }
}

export default new Vuex.Store({
  state: getDefaultState,

  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer
    },
    setAlert(state, payload) {
      state.alert = payload
    },
    resetAlert(state) {
      state.alert = null
    },
    showModal(state, { title, text, action, func }) {
      state.modal.visible = true
      state.modal.text = text
      state.modal.title = title
      state.modal.action.process = action.process
      state.modal.action.cancel = action.cancel
      state.modal.action.function = func
    },
    hideModal(state) {
      state.modal.visible = false
      state.modal.title = getDefaultState().modal.title
      state.modal.text = getDefaultState().modal.text
      state.modal.action = getDefaultState().modal.action
    },
    startModalLoading(state) {
      state.modal.loading = true
    },
    stopModalLoading(state) {
      state.modal.loading = false
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer')
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer
    },
    getModalVisibility: state => state.modal.visible,
    getModalTitle: state => state.modal.title,
    getModalText: state => state.modal.text,
    getModalAction: state => state.modal.action,
    getModalLoading: state => state.modal.loading
  },
  modules: {
    auth,
    car,
    service,
    partner,
    user,
    claim,
    post,
    offDay,
    customer,
    worktime,
    prefecture,
    task,
    project,
    sites,
    photo,
    coatingAfter,
    claimCategory,
    visitMemo,
    floorMaterial
  },
  plugins: [createPersistedState()]
})
