<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
    class="transition-none"
  >
    <v-list nav dense>
      <template v-for="(item, i) in items">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-1"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children"
          :key="item.title"
          v-model="item.model"
          class="transition-none"
        >
          <template v-slot:prependIcon>
            <v-icon size="20">{{ item.icon }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                class="grey-darken-1--text transition-none font-weight-bold"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.link"
            link
            class="transition-none"
          >
            <v-list-item-action>
              <v-icon size="20">mdi-menu-right</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text transition-none">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="20" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="grey-darken-1--text transition-none font-weight-bold"
              link
              @click="closeItemGroups"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    source: String
  },
  data() {
    return {
      items: [
        {
          title: 'スケジュール',
          model: false,
          icon: '$calendar',
          children: [
            {
              title: ' 従業員スケジュール',
              link: '/schedule/task-view'
            },

            {
              title: '月間スケジュール',
              link: '/schedule/monthly'
            },
            {
              title: '3日間スケジュール',
              link: '/schedule/three-days'
            },

            {
              title: ' タスクの新規登録',
              link: '/task/create'
            }
          ]
        },
        {
          title: '勤怠',
          model: false,
          icon: '$workTime',
          children: [
            {
              title: '勤怠一覧',
              link: '/work-time/list'
            },
            {
              title: '月稼働時間',
              link: '/work-time/operating-hours'
            },
            {
              title: '月間データダウンロード',
              link: '/work-time/monthly'
            },
            {
              title: '勤怠の新規作成',
              link: '/work-time/create'
            }
          ]
        },
        {
          title: '顧客',
          model: false,
          icon: '$customer',
          children: [
            {
              title: '顧客一覧',
              link: '/customer/list'
            },
            {
              title: '顧客の新規追加',
              link: '/customer/create'
            }
          ]
        },
        {
          title: 'お知らせ',
          model: false,
          icon: '$infoChat',
          children: [
            {
              title: 'お知らせ一覧',
              link: '/post/list'
            },
            {
              title: 'お知らせの新規登録',
              link: '/post/create'
            }
          ]
        },
        {
          title: '休暇',
          model: false,
          icon: '$offDay2',
          link: '/off-day/list'
        },
        {
          title: 'クレーム管理',
          model: false,
          icon: '$claim',
          children: [
            {
              title: 'クレーム一覧',
              link: '/claim/list'
            },
            {
              title: 'クレーム登録',
              link: '/claim/create'
            },
            {
              title: 'クレームタグ管理',
              link: '/management/category'
            }
          ]
        },
        {
          title: 'その他',
          model: false,
          icon: '$setting',
          children: [
            {
              title: '従業員管理',
              link: '/management/employee'
            },
            {
              title: 'パートナー会社管理',
              link: '/management/partner'
            },
            {
              title: '車両管理',
              link: '/management/car'
            },
            {
              title: '色設定',
              link: '/management/service/color'
            },
            {
              title: '床材設定',
              link: '/management/floor-material'
            },
            {
              title: '最近の更新履歴',
              link: '/management/recent-updates'
            }
          ]
        }
      ],
      sidebarWidth: 240,
      sidebarMinWidth: 76
    }
  },
  mounted() {
    let params = {}
    if(this.$route.name === 'CustomerViewDetailVisitMemo'){
      params.deletedUsers = true
    }
    if (this.$route.matched[0]?.name === 'CustomerViewDetail') {
      this.$store.dispatch('GET_GENERAL_USER', {...params , employeeType: '通常業務'})
      this.$store.dispatch('GET_DAILY_CLEANING_USER', {...params, employeeType: '日常清掃員'})
    }
  },
  computed: {
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get() {
        return this.drawer
      },
      set(newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),

    closeItemGroups() {
      const index = this.items.findIndex(object => {
        return object.model === true
      })
      if (this.items) {
        this.items[index].model = false
      }
    }
  }
}
</script>

<style src="./Sidebar.scss" lang="scss" />
