export const CUSTOMER_GET_ALL = 'CUSTOMER_GET_ALL'
export const CUSTOMER_GET_ALL_SUCCESS = 'CUSTOMER_GET_ALL_SUCCESS'
export const CUSTOMER_GET_ALL_ERROR = 'CUSTOMER_GET_ALL_ERROR'
export const CUSTOMER_GET = 'CUSTOMER_GET'
export const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS'
export const CUSTOMER_GET_ERROR = 'CUSTOMER_GET_ERROR'
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE'
export const CUSTOMER_GET_ALL_CLAIM = 'CUSTOMER_GET_ALL_CLAIM'
export const CUSTOMER_GET_ALL_CLAIM_SUCCESS = 'CUSTOMER_GET_ALL_CLAIM_SUCCESS'
export const CUSTOMER_GET_ALL_CLAIM_ERROR = 'CUSTOMER_GET_ALL_CLAIM_ERROR'
export const CUSTOMER_CREATE_CLAIM = 'CUSTOMER_CREATE_CLAIM'
export const CUSTOMER_EDIT_CLAIM = 'CUSTOMER_EDIT_CLAIM'
export const CUSTOMER_GET_ALL_SITE_SUCCESS = 'CUSTOMER_GET_ALL_SITE_SUCCESS'
export const CUSTOMER_GET_ALL_SITE_ERROR = 'CUSTOMER_GET_ALL_SITE_ERROR'
export const CUSTOMER_CREATE_SITE = 'CUSTOMER_CREATE_SITE'
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE'
export const CUSTOMER_UPDATE_SITE = 'CUSTOMER_UPDATE_SITE'
export const CUSTOMER_TASK_GET = 'CUSTOMER_TASK_GET'
export const CUSTOMER_TASK_GET_SUCCESS = 'CUSTOMER_TASK_GET_SUCCESS'
export const CUSTOMER_TASK_GET_ERROR = 'CUSTOMER_TASK_GET_ERROR'
export const GET_SERVICE_TYPE = 'GET_SERVICE_TYPE'
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS'
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR'
export const CUROMER_TASKS_FILTER = 'CUROMER_TASKS_FILTER'
export const CUSTOMER_DELETE_CLAIM = 'CUSTOMER_DELETE_CLAIM'
export const DELETE_CUSTOMER_SITE = 'DELETE_CUSTOMER_SITE'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const CUSTOMER_CREATE_SERVICE_FROM_SITE =
  'CUSTOMER_CREATE_SERVICE_FROM_SITE'
export const CUSTOMER_GET_ALL_AFTER_SERVICE = 'CUSTOMER_GET_ALL_AFTER_SERVICE'
export const CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS =
  'CUSTOMER_GET_ALL_AFTER_SERVICE_SUCCESS'
export const CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR =
  'CUSTOMER_GET_ALL_AFTER_SERVICE_ERROR'
export const ACTIVITIES_GET_ALL_SUCCESS = 'ACTIVITIES_GET_ALL_SUCCESS'
