var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "transition-none",
    class: {
      'drawer-mini': !_vm.DRAWER_STATE
    },
    attrs: {
      "app": "",
      "clipped": "",
      "mini-variant": !_vm.DRAWER_STATE,
      "width": _vm.sidebarWidth,
      "permanent": _vm.$vuetify.breakpoint.mdAndUp,
      "temporary": _vm.$vuetify.breakpoint.smAndDown,
      "mini-variant-width": _vm.sidebarMinWidth
    },
    model: {
      value: _vm.DRAWER_STATE,
      callback: function callback($$v) {
        _vm.DRAWER_STATE = $$v;
      },
      expression: "DRAWER_STATE"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_vm._l(_vm.items, function (item, i) {
    return [item.heading ? _c('v-row', {
      key: item.heading,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('span', {
      staticClass: "text-body-1 subheader",
      class: item.heading && _vm.DRAWER_STATE ? 'show ' : 'hide',
      staticStyle: {
        "padding-left": "32px"
      }
    }, [_vm._v(" " + _vm._s(item.heading) + " ")])]), _c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "6"
      }
    })], 1) : item.divider ? _c('v-divider', {
      key: i,
      staticClass: "my-1",
      attrs: {
        "dark": ""
      }
    }) : item.children ? _c('v-list-group', {
      key: item.title,
      staticClass: "transition-none",
      attrs: {
        "color": "primary"
      },
      scopedSlots: _vm._u([{
        key: "prependIcon",
        fn: function fn() {
          return [_c('v-icon', {
            attrs: {
              "size": "20"
            }
          }, [_vm._v(_vm._s(item.icon))])];
        },
        proxy: true
      }, {
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "grey-darken-1--text transition-none font-weight-bold"
          }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.model,
        callback: function callback($$v) {
          _vm.$set(item, "model", $$v);
        },
        expression: "item.model"
      }
    }, _vm._l(item.children, function (child, i) {
      return _c('v-list-item', {
        key: i,
        staticClass: "transition-none",
        attrs: {
          "to": child.link,
          "link": ""
        }
      }, [_c('v-list-item-action', [_c('v-icon', {
        attrs: {
          "size": "20"
        }
      }, [_vm._v("mdi-menu-right")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        staticClass: "grey-darken-1--text transition-none"
      }, [_vm._v(" " + _vm._s(child.title) + " ")])], 1)], 1);
    }), 1) : _c('v-list-item', {
      key: item.text,
      attrs: {
        "color": "primary",
        "to": item.link === '#' ? null : item.link,
        "link": ""
      }
    }, [_c('v-list-item-action', [_c('v-icon', {
      attrs: {
        "size": "20",
        "color": item.color ? item.color : ''
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "grey-darken-1--text transition-none font-weight-bold",
      attrs: {
        "link": ""
      },
      on: {
        "click": _vm.closeItemGroups
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1)];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }