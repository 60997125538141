import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import i18n from '@/plugins/i18n'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/plugins/v-mask'
import '@/plugins/vee-validate'

require('@/services/api')

Vue.config.productionTip = false
Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')