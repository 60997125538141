import axios from 'axios'
import {
  GET_ALL_PROJECT,
  GET_ALL_SUCCESS_PROJECT,
  GET_ALL_ERROR_PROJECT,
  GET_PROJECT_SVC,
  GET_SUCCESS_PROJECT_SVC,
  GET_ERROR_PROJECT_SVC
} from '@/store/actions/project'

function initialState() {
  return {
    status: '',
    projects: [],
    customeAutocompData: [],
    project_svc: {}
  }
}

const state = initialState()

const getters = {
  allProjects: state => state.projects,
  getProjectSvc: state => state.project_svc
}

const actions = {
  [GET_ALL_PROJECT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/projects', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_ALL_SUCCESS_PROJECT', {
            projects: data.data.projects
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_ERROR_PROJECT', { error: error })

          reject(error)
        })
    })
  },
  [GET_PROJECT_SVC]: ({ commit, dispatch }, { id, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/projects/' + id + '/with-svc', {
          params: params
        })
        .then(response => {
          let data = response.data
          commit('GET_SUCCESS_PROJECT_SVC', {
            project: data.data.project
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ERROR_PROJECT_SVC', { error: error })

          reject(error)
        })
    })
  }
}

const mutations = {
  [GET_ALL_SUCCESS_PROJECT]: (state, params) => {
    state.status = 'success'
    state.projects = params.projects
    state.customeAutocompData = []
    params.projects.filter(project => {
      const name =
        project.customer.name +
        ' ' +
        project.customer.furigana_name +
        ' ' +
        '/' +
        ' ' +
        project.site.id +
        ' ' +
        project.site.name
      state.customeAutocompData.push({ id: project.customer.id, name: name })
    })
  },
  [GET_ALL_ERROR_PROJECT]: state => {
    state.status = 'error'
  },
  [GET_SUCCESS_PROJECT_SVC]: (state, params) => {
    state.status = 'success'
    state.project_svc = params.project
  },
  [GET_ERROR_PROJECT_SVC]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
